import { Injectable } from '@angular/core';
import { MatSnackBar } from "@angular/material/snack-bar";
import { HttpClient,HttpEvent,HttpHandler,HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Customers } from  '../models/vindi.customers.model';

import { DataService } from './data.service';
import { Observable, EMPTY } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})

export class CustomersService {

      
  constructor(private service: DataService, private snackBar: MatSnackBar, private http: HttpClient) {}
  

  getByidCustomers(id:number): Observable<Customers[]> {
    return this.http.get<Customers[]>
    (this.service.baseUrl+'/vindi/customers/'+id).pipe(
      map((obj) => obj['customers']),
      catchError((e) => this.errorHandler(e))
    );
  }
  
  create(customer ): Observable<Customers> {
    return this.http.post<Customers>(this.service.baseUrl+'/vindi/customers', customer).pipe(
        map((obj) => obj),
        catchError((e) => this.errorHandler(e))
      );
  }

  update(id,customer ): Observable<Customers> {
    return this.http.put<Customers>(this.service.baseUrl+'/vindi/customers/'+id, customer).pipe(
        map((obj) => obj),
        catchError((e) => this.errorHandler(e))
      );;
  }

  errorHandler(e: any): Observable<any> {
    this.service.showMessage("Ocorreu um erro! " + e.catchError, true);
    return EMPTY;
  }
 
}
