<br><br><br>
<mat-card>

    <div class="teste">
        <i class="material-icons">
            person
        </i>
        <span>
            Usuários
        </span>
    </div>
    <br>

    <mat-form-field class="search-field" appearance="outline">
        <mat-icon (click)="Search()" matSuffix>search</mat-icon>
        <input matInput placeholder="Pesquise aqui" [(ngModel)]="search" (keyup.enter)="Search()">
    </mat-form-field>

<!-- <mat-tab animationDuration="0ms">
    <mat-tab> -->
        <div class="mat-elevation-z4">
            <table mat-table [dataSource]="objAtivos">
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef>Código do usuário</th>
                    <td mat-cell *matCellDef="let row">{{row.id}}</td>
                </ng-container>

                <ng-container matColumnDef="nome">
                    <th mat-header-cell *matHeaderCellDef>Nome</th>
                    <td mat-cell *matCellDef="let row">{{row.nome}}</td>
                </ng-container>

                <ng-container matColumnDef="login">
                    <th mat-header-cell *matHeaderCellDef>Login</th>
                    <td mat-cell *matCellDef="let row">{{row.login}}</td>
                </ng-container>
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef>Ações</th>
                    <td mat-cell *matCellDef="let row">
                        <button  class="delete-btn" (click)="gerenciarUsuario('usuarioUpdate', row.id)">
                            <mat-icon
                            matTooltipPosition="right"
                            matTooltipClass="allow-cr"
                            matTooltip="Gerenciar Usuario">edit</mat-icon>
                          </button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsAtivos"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsAtivos;"></tr>
            </table>
        </div>
        <mat-paginator [length]="lengthAtivos" [pageSize]="pageSizeAtivos" [pageSizeOptions]="pageSizeOptionsAtivos"
            (page)="pageEvent = clickPageAtivos($event)">
        </mat-paginator>
    <!-- </mat-tab>
</mat-tab-group> -->
</mat-card>