<br><br><br>

  <mat-card>
    <mat-card-title class="titulo">{{nome}}</mat-card-title>  
<mat-tab-group class="font-small"  animationDuration="0ms" (selectedTabChange)="tabClick($event)">
    <mat-tab>
        <ng-template mat-tab-label>
            Dados Cadastrais
           </ng-template>
        <app-edit-contabilidade 
        (receiverNome)="receiverNome($event)"
        ></app-edit-contabilidade>
    </mat-tab>
    <mat-tab *ngIf="id > 0">
        <ng-template mat-tab-label>
            Clientes
           </ng-template>
           <app-list-contabil-clientes ></app-list-contabil-clientes>
    </mat-tab>
    <mat-tab *ngIf="id > 0">
        <ng-template mat-tab-label>
            Usuários do portal
           </ng-template> 
           <app-list-usuario-contabil></app-list-usuario-contabil>
    </mat-tab>
</mat-tab-group>
</mat-card>