import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './views/home/home.component';
import { ClienteEditComponent } from './views/cliente/edit/edit-cliente.component';
import { ClienteListComponent } from './views/cliente/list/list-cliente.component';
import { ClienteInfoComponent } from './views/cliente/info/info-cliente.component';
import { NfservicoListComponent } from './views/nfservico/list/list-nfservico.component';
import { ListContabilidadeComponent } from './views/contabilidade/list/list-contabilidade.component';
import { EditContabilidadeComponent } from './views/contabilidade/edit/edit-contabilidade.component';
import { ActionTabContabilidadeComponent } from './views/contabilidade/action/action-tab-contabilidade/action-tab-contabilidade.component';
import { UsuariosComponent } from './views/usuarios/usuarios.component';
import { LoginComponent } from './views/login/login.component';
import { FramePageComponent } from './views/master/frame-page/frame-page.component';
import { AuthService } from './services/auth.service';
import { FechamentoComponent } from './views/fechamento/fechamento.component';

const routes: Routes = [
  { path: "***", redirectTo: "home", pathMatch: "full" },
  { path: "", redirectTo: "home", pathMatch: "full" },
  {
    path: "login",
    component: LoginComponent
  },
  {
    path: "",
    component: FramePageComponent,
    canActivate: [AuthService],
    children: [
      {
        path: "home",
        component: HomeComponent
      },
      {
        path: "clientes",
        component: ClienteListComponent
      },
      {
        path: "clientes/edit/:id",
        component: ClienteEditComponent
      },
      {
        path: "clientes/info/:id",
        component: ClienteInfoComponent
      },
      {
        path: "nfservicos",
        component: NfservicoListComponent
      },
      {
        path: "contabilidade",
        component: ListContabilidadeComponent
      },
      {
        path: "contabilidade/edit/:id",
        component: ActionTabContabilidadeComponent
      },
      {
        path:"fechamento",
        component: FechamentoComponent
      },
      {
        path: "usuarios",
        component: UsuariosComponent
      },
    ]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes), RouterModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
