import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ContabilidadeService } from 'src/app/services/contabilidade.service';
import { DialogService } from 'src/app/services/dialog.service';
import { GrupoEmpresaService } from 'src/app/services/grupoempresa.service';

@Component({
  selector: 'app-list-usuario-contabil',
  templateUrl: './list-usuario-contabil.component.html',
  styleUrls: ['./list-usuario-contabil.component.scss']
})
export class ListUsuarioContabilComponent implements OnInit, OnDestroy {
  idPri: number;
  objAtivos: any[];
  arrChecked: any[] = [0];
  
  public autohide = 3000;

  constructor(private _contabilidadeService: ContabilidadeService,
    private _route: ActivatedRoute,
    private _dialogService: DialogService,
    public snackBar: MatSnackBar) { }

  displayedColumnsAtivos = ['id', 'nome', 'razao', 'action'];
  displayedColumnsInativos = ['id', 'nome', 'razao', 'action'];
  getSubscription: Subscription;
  postSubscription: Subscription;
  delSubscription: Subscription;
  dialogSubscription: Subscription;
  selectedIndex = 0;
  search: String;
  currentPageAtivos: number;
  lengthAtivos = 0;
  pageSizeAtivos = 25;
  pageSizeOptionsAtivos: number[] = [25, 50, 100];
  pageEvent: PageEvent;

  ngOnInit(): void {
    this.idPri = +this._route.snapshot.paramMap.get("id");
    this.search = '';
    this.currentPageAtivos = 1;
    this.getAtivos();
  }

  ngOnDestroy(): void {
  }

  public getAtivos(event?: PageEvent) {
    this.selectedIndex = 0;
    this.getSubscription = this._contabilidadeService.readByIdUser(this.idPri, this.search)
      .subscribe((res: any) => {
        let objAtivos = res.usuarioEntidade;
        this.lengthAtivos = res.totalActive;
        this.objAtivos = objAtivos.map((x, index) => {
          x.Usuario.isAtivo == 'S' ? this.arrChecked[index] = true : this.arrChecked[index] = false;
          return x.Usuario;
        });
      });
    return event;
  }
  gerenciarUsuario(acao, idUsuario) {
    this.dialogSubscription = this._dialogService.gerenciarUsuario(this.idPri, acao, idUsuario)
      .afterClosed()
      .subscribe((res: any) => this.getAtivos());
  }
  pesquisar() {
    this.getAtivos();
  }
  clickPageAtivos(event: PageEvent) {
    this.currentPageAtivos = event.pageIndex + 1;
    this.pageSizeAtivos = event.pageSize;
    this.getAtivos(event);
    return event;
  }
  desativarObj(id) {

  }
  color = 'accent';
  checked = false;
  changed(event, id) {
    event.checked === true ?
      this.reativarUsuario(id) :
      this.desvincular(id);
  }

  reativarUsuario(id) {
    this.getSubscription = this._contabilidadeService.reativarClienteUserRelation(id)
      .subscribe((res: any) => {
        this.snackBar.open(res.message, undefined, {
          duration: this.autohide,
          panelClass: ['ok-snackbar']
        });
      });
  }

  desvincular(id) {

    let msg = "Deseja desvincular usuário?";
    this._dialogService
      .openConfirmDialog(msg, "Desvincular")
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.getSubscription = this._contabilidadeService.delClienteUserRelation(id)
            .subscribe((res: any) => {
              this.snackBar.open(res.message, undefined, {
                duration: this.autohide,
                panelClass: ['ok-snackbar']
              });
            });
        }
      });
    this.getAtivos();
  }
}
