import { Injectable } from '@angular/core';
import { MatSnackBar } from "@angular/material/snack-bar";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Dashboard } from  '../models/dashboard.model';

import { DataService } from './data.service';
import { Observable, EMPTY } from "rxjs";
import { map, catchError } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  
  constructor(private service: DataService, private snackBar: MatSnackBar, private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'})
  }

  read(): Observable<Dashboard> {
    return this.http.get<Dashboard>(this.service.baseUrl+'/dashboard', this.httpOptions).pipe(
      map((obj) => obj),
      catchError((e) => this.errorHandler(e))
    );
  }

 errorHandler(e: any): Observable<any> {
    this.service.showMessage("Ocorreu um erro!", true);
    return EMPTY;
  }
}
