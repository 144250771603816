<ng-template mat-tab-label>
    <span>Chave de acesso</span>
</ng-template>
<div class="row" style="padding:15px;" [formGroup]="form">
    <mat-form-field class="inputMedium date">
        <mat-label>Data de Validade</mat-label>
        <input matInput (dateChange)="formatData($event)" [matDatepicker]="picker1" formControlName="dateIni">
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
    </mat-form-field>
</div>

<div>
    <button class="botaoPrimario" mat-raised-button [disabled]="form.invalid" (click)="gerarChave()">
        Gerar Chave
    </button>
    <button class="botaoSecundario" style="margin-right:10px;" mat-raised-button (click)="fecharDialog()">
      Voltar
    </button>

  </div>


