<div>
    <div class="container">
      <div class="row close-icon">
        <mat-icon (click)="closeDialog()" [mat-dialog-close]="false" 
        >close</mat-icon>
      </div>
      <div class="row message">
        <span class="content-span full-width">
          {{ data.message }}
        </span>
      </div>
      <div class="row content-button">
        <button
          class="mat-flat-button"
          (click)="closeDialog()"
          id="not-confirm-button"
          [mat-dialog-close]="false"
        >
          Cancelar
        </button>
        <button
          class="mat-flat-button delete-button"
          id="confirm-button"
          [mat-dialog-close]="true"
        >
        {{ data.botao }}
        </button>
      </div>
    </div>
  </div>
  