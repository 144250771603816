
<mat-card class="card-dados">
    <div class="row">

        <div class="col-9">
            <h1 *ngIf="id==0" class="title">Novo Escritório</h1>
            <h1 *ngIf="id>0" class="title">Editar Escritório</h1>
        </div>
        <div class="col-3">
            <mat-slide-toggle [(ngModel)]="isAtivo" class="example-margin" [color]="color" (change)="changed($event )">
                <span *ngIf="isAtivo === true ">Ativo</span>
                <span *ngIf="isAtivo === false">Inativo</span>
            </mat-slide-toggle>
        </div>
    </div>

    <form [formGroup]="form" class="dado">
        <div *ngIf="iscnpj == true">
            <mat-form-field appearance="outline">
                <mat-label>CNPJ</mat-label>
                <input matInput placeholder="CNPJ" formControlName="cnpj" [(ngModel)]="objCliente.cnpj" name="cnpj">
            </mat-form-field>
            <mat-form-field class="" appearance="outline">
                <mat-label>Inscrição Estadual</mat-label>
                <input formControlName="ie" matInput placeholder="Inscrição Estadual" [(ngModel)]="objCliente.ie"
                    name="ie">
            </mat-form-field>
        </div>
        <div *ngIf="iscnpj == false">
            <mat-form-field appearance="outline">
                <mat-label>CPF</mat-label>
                <input formControlName="cpf" matInput placeholder="CPF" [(ngModel)]="objCliente.cpf" name="cpf">
            </mat-form-field>
        </div>
        <div *ngIf="iscnpj == false">
            <mat-label>RG</mat-label>
            <mat-form-field appearance="outline">
                <input formControlName="rg" matInput placeholder="RG" [(ngModel)]="objCliente.rg" name="rg">
            </mat-form-field>
        </div>
        <div class="dado">
            <mat-form-field class="nome" appearance="outline">
                <mat-label>Nome</mat-label>
                <input formControlName="nome" matInput placeholder="Nome" [(ngModel)]="objCliente.nome" name="nome">
            </mat-form-field>
        </div>
        <div class="dado">
            <mat-form-field class="razao" appearance="outline">
                <mat-label>Razão Social</mat-label>
                <input formControlName="razao" matInput placeholder="Razão Social" [(ngModel)]="objCliente.razao"
                    name="razao">
            </mat-form-field>
        </div>
        <div class="dado">
            <mat-form-field class="rua" appearance="outline">
                <mat-label>Endereço</mat-label>
                <input formControlName="endereco" matInput placeholder="Endereco" [(ngModel)]="objCliente.endereco"
                    name="endereco">
            </mat-form-field>
            <mat-form-field class="nro" appearance="outline">
                <mat-label>Número</mat-label>
                <input formControlName="enderecoNumero" matInput placeholder="Nro"
                    [(ngModel)]="objCliente.enderecoNumero" name="nro">
            </mat-form-field>
        </div>
        <div class="dado">
            <mat-form-field class="comple" appearance="outline">
                <mat-label>Complemento</mat-label>
                <input formControlName="complemento" matInput placeholder="Complemento"
                    [(ngModel)]="objCliente.complemento" name="complemento">
            </mat-form-field>
            <mat-form-field class="bairro" appearance="outline">
                <mat-label>Bairro</mat-label>
                <input formControlName="bairro" matInput placeholder="Bairro" [(ngModel)]="objCliente.bairro"
                    name="bairro">
            </mat-form-field>
        </div>
        <div class="dado">
            <mat-form-field class="cep" appearance="outline">
                <mat-label>CEP</mat-label>
                <input formControlName="cep" matInput maxlength="9" placeholder="Cep" [(ngModel)]="objCliente.cep"
                    name="cep">
            </mat-form-field>
            <mat-form-field class="cidade" appearance="outline">
                <mat-label>Cidade</mat-label>
                <input formControlName="cidade" matInput placeholder="Cidade" [(ngModel)]="objCliente.cidade"
                    name="cidade">
            </mat-form-field>
            <mat-form-field class="uf" appearance="outline">
                <mat-label>UF</mat-label>
                <input formControlName="uf" matInput maxlength="2" placeholder="UF" [(ngModel)]="objCliente.uf"
                    name="uf">
            </mat-form-field>
        </div>
        <div class="dado">
            <mat-form-field class="fone" appearance="outline">
                <mat-label>Telefone</mat-label>
                <span matPrefix>+55 &nbsp;</span>
                <input formControlName="Telefone" type="tel" matInput placeholder="555-555-1234"
                    [(ngModel)]="objCliente.fone" name="fone">
            </mat-form-field>
            <mat-form-field class="contato" appearance="outline">
                <mat-label>Contato</mat-label>
                <input formControlName="contato" matInput placeholder="Contato" [(ngModel)]="objCliente.contato"
                    name="contato">
            </mat-form-field>
        </div>
        <div class="dado">
            <mat-form-field class="emailComercial" appearance="outline">
                <mat-label>Email (Comercial)</mat-label>
                <input formControlName="email" matInput placeholder="Ex. pat@example.com" [(ngModel)]="objCliente.email"
                    name="emailCom">
                <mat-hint align="start"><strong>Separar com ponto e vírgula</strong> </mat-hint>
            </mat-form-field>
        </div>
    </form><br>
    <button class="botaoPrimario" mat-raised-button (click)="submit()">
        Salvar
    </button>

    <button class="botaoSecundario" mat-raised-button (click)="voltar()">
        Cancelar
    </button>
    <br><br><br>
</mat-card>