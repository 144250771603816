<br><br><br>
<mat-card>
    <div class="teste">
        <i class="material-icons">
            group
        </i>
        <span>
            Clientes
        </span>
    </div>
    <br>
    <div fxLayout="row">
        <mat-form-field class="search-field" appearance="outline" >
            <mat-icon (click)="Search()" matSuffix >search</mat-icon>
            <input  matInput placeholder="Pesquisar clientes"  
            [(ngModel)]="search" (keyup.enter)="Search()">
        </mat-form-field>
        <button class="botaoPrimario" mat-raised-button (click)="Add()" fxLayoutAlign="end center">
            Novo Cliente
        </button>
    </div>


<mat-tab-group (selectedTabChange)="tabClick($event)" animationDuration="0ms" [(selectedIndex)]=selectedIndex>
    <mat-tab label="Ativos">
        <div class="mat-elevation-z4">
            <table mat-table [dataSource]="clientesAtivos">
            <ng-container matColumnDef="id" >
                <th mat-header-cell *matHeaderCellDef>Código</th>
                <td mat-cell *matCellDef="let row">{{row.id}}</td>
            </ng-container>
        
            <ng-container matColumnDef="nome">
                <th mat-header-cell *matHeaderCellDef>Nome</th>
                <td mat-cell *matCellDef="let row">{{row.nome}}</td>
            </ng-container>
        
            <ng-container matColumnDef="razao">
                <th mat-header-cell *matHeaderCellDef>Razão Social</th>
                <td mat-cell *matCellDef="let row">{{row.razao}}</td>
            </ng-container>
            <ng-container matColumnDef="cnpj">
                <th mat-header-cell *matHeaderCellDef>CNPJ</th>
                <td mat-cell *matCellDef="let row">{{row.cnpj}}</td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef width="10%">Ações</th>
                <td mat-cell *matCellDef="let row">
                    <a  matTooltip="Editar" routerLink="/clientes/edit/{{ row.id }}" class="edit">
                        <i class="material-icons">
                            edit
                        </i>
                    </a>
                    <a  matTooltip="informações" routerLink="/clientes/info/{{ row.id }}" class="info">
                        <i class="material-icons">
                            open_in_new
                        </i>
                    </a>
                    <button style="right:0px;"  class="delete-btn" (click)="desativarObj(row.id)">
                        <mat-icon
                        matTooltipPosition="right"
                        matTooltipClass="allow-cr"
                        matTooltip="Desativar">delete</mat-icon>
                      </button>
                </td>

            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="displayedColumnsAtivos"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsAtivos;"></tr>
            </table>
        </div>
        <mat-paginator 
              [length]="lengthAtivos"
              [pageSize]="pageSizeAtivos"
              [pageSizeOptions]="pageSizeOptionsAtivos"
              (page)="pageEvent = clickPageAtivos($event)">
        </mat-paginator>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <span>Inativos</span>
        </ng-template>
        <div class="mat-elevation-z4">
            <table mat-table [dataSource]="clientesInativos">
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef>Código</th>
                <td mat-cell *matCellDef="let row">{{row.id}}</td>
            </ng-container>
            <ng-container matColumnDef="nome">
                <th mat-header-cell *matHeaderCellDef>Nome</th>
                <td mat-cell *matCellDef="let row">{{row.nome}}</td>
            </ng-container>
            <ng-container matColumnDef="razao">
                <th mat-header-cell *matHeaderCellDef>Razão Social</th>
                <td mat-cell *matCellDef="let row">{{row.razao}}</td>
            </ng-container>
            <ng-container matColumnDef="cnpj">
                    <th mat-header-cell *matHeaderCellDef>CNPJ</th>
                    <td mat-cell *matCellDef="let row">{{row.cnpj}}</td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef>Ações</th>
                <td mat-cell *matCellDef="let row">
                    <a routerLink="/clientes/edit/{{ row.id }}" class="edit">
                        <i class="material-icons">
                            edit
                        </i>
                    </a>
                    <a routerLink="/clientes/info/{{ row.id }}" class="info">
                        <i class="material-icons">
                            open_in_new
                        </i>
                    </a>
                    <button  style="right:0px;" class="delete-btn" (click)="ativarObj(row.id)">
                        <mat-icon
                        matTooltipPosition="right"
                        matTooltipClass="allow-cr"
                        matTooltip="Ativar">restore</mat-icon>
                    </button>
                </td>

            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="displayedColumnsInativos"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsInativos;"></tr>
            </table>
        </div>
        <mat-paginator 
            [length]="lengthInativos"
            [pageSize]="pageSizeInativos"
            [pageSizeOptions]="pageSizeOptionsInativos"
            (page)="pageEvent = clickPageInativos($event)">
        </mat-paginator>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <span>Teste</span>
        </ng-template>
        <div class="mat-elevation-z4">
            <table mat-table [dataSource]="clientesTeste">
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef>Código</th>
                <td mat-cell *matCellDef="let row">{{row.id}}</td>
            </ng-container>
            <ng-container matColumnDef="nome">
                <th mat-header-cell *matHeaderCellDef>Nome</th>
                <td mat-cell *matCellDef="let row">{{row.nome}}</td>
            </ng-container>
            <ng-container matColumnDef="cnpj">
                    <th mat-header-cell *matHeaderCellDef>CNPJ</th>
                    <td mat-cell *matCellDef="let row">{{row.cnpj}}</td>
            </ng-container>
            <ng-container matColumnDef="dtValidaChave">
                    <th mat-header-cell *matHeaderCellDef>Data Chave</th>
                    <td mat-cell *matCellDef="let row">{{row.dtValidaChave | date:"dd/MM/yyyy"}}</td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef>Ações</th>
                <td mat-cell *matCellDef="let row">
                    <a routerLink="/clientes/edit/{{ row.id }}" class="edit">
                        <i class="material-icons">
                            edit
                        </i>
                    </a>
                    <a routerLink="/clientes/info/{{ row.id }}" class="info">
                        <i class="material-icons">
                            open_in_new
                        </i>
                    </a>
                    <button  style="right:0px;" class="check-btn" (click)="ativarClienteTeste(row.id)">
                        <mat-icon
                        matTooltipPosition="right"
                        matTooltipClass="allow-cr"
                        matTooltip="Ativar">check_circle</mat-icon>
                    </button>
                </td>

            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="displayedColumnsTeste"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsTeste;"></tr>
            </table>
        </div>
        <mat-paginator 
            [length]="lengthTestes"
            [pageSize]="pageSizeTeste"
            [pageSizeOptions]="pageSizeOptionsTeste"
            (page)="pageEvent = clickPageTeste($event)">
        </mat-paginator>
    </mat-tab>
</mat-tab-group>
</mat-card>