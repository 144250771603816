import { Component, OnDestroy, OnInit } from '@angular/core';
import { NfservicoService } from '../../../services/nfservico.service';
import { NFServico, Entidade } from 'src/app/models/nfservico.model';
import { Router } from '@angular/router';
import {PageEvent} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
//import { NgxSpinnerService } from "ngx-spinner";
//import Swal from 'sweetalert2/dist/sweetalert2.js';



@Component({
  selector: 'app-list-nfservico',
  templateUrl: './list-nfservico.component.html',
  styleUrls: ['./list-nfservico.component.scss']
})

export class NfservicoListComponent implements OnInit, OnDestroy {
   
  objNotServ: any[];
  public dateGroup: FormGroup;
  enabledDatePicker: boolean = false;
  defaultValue = "Todos";
  data1Filtro: String = '';
  data2Filtro: String= '';
  getSubscription: Subscription;
  
  //nfservicosTrans: NFServico[]
  nfservicosTrans: NFServico = {} as NFServico;
  displayedColumnsTrans = ['id', 'dtEmissao', 'nome','emailBoleto', 'numeroNota' , 'vServico','status','dtEmail','razao', 'actions'];
  nfservicosAguard: NFServico[]
  displayedColumnsAguard = ['id', 'dtEmissao', 'nome','emailBoleto', 'numeroNota' , 'vServico','status','actions'];
  nfservicosCanc: NFServico[]
  displayedColumnsCanc = ['id', 'dtEmissao', 'nome','emailBoleto', 'numeroNota' , 'vServico','status','actions'];

  msgSpinner: string;

  constructor(
    private nfservicoService: NfservicoService, 
    private router: Router, 
    private dialog: MatDialog,    
    private fb: FormBuilder,
  //  private spinnerService: NgxSpinnerService
    ) {
    this.dateGroup = this.fb.group({
      dataSelect: '',
      dateIni: '',
      dateFin: ''
    });
    
   }

   search: String;
   habilitaFiltro: boolean = true;
   selectedIndex= 0;
   currentPage: number;
   length = 0;
   pageSize = 25;
   pageSizeOptions: number[] = [25, 50, 100];
   pageEvent: PageEvent;
   
  ngOnDestroy(): void {
    if(this.getSubscription){
      this.getSubscription.unsubscribe();
    }
  }
  


  ngOnInit(): void {
    this.search = '';
    this.selectedIndex = 0;
    this.length = 0;
    this.pageSize = 25;
    this.currentPage = 1;
    this.tabClick({index:0});
  }

  tabClick(tab) {
    let index = tab.index;
    if (index == 0) {
      this.habilitaFiltro = true;
      this.getTransmitidas();
      // this.filtrarValores();
    } else if (index == 1) {
      this.habilitaFiltro = false;
      this.getAguardando();
    } else if (index == 2) {
      this.habilitaFiltro = false;
      this.getCanceladas();
    } 
  }
  
  // clickPage(e: any) {
  //   this.currentPage = e.pageIndex;
  //   this.pageSize = e.pageSize;
  //   this.getTransmitidas();
  // }

  clickPage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getTransmitidas();
    return e;
  }

  getTransmitidas(){
    this.nfservicoService.read('T',this.search, this.currentPage, this.pageSize, this.data1Filtro, this.data2Filtro).subscribe((res: any) => {
      this.nfservicosTrans = res.nfservico;
      this.objNotServ = res.sum;
      this.length = res.totalPage;
      this.objNotServ[0].media = this.objNotServ[0].media.toFixed(2);
      this.objNotServ[0].total = this.objNotServ[0].total.toFixed(2);
  });
   }
  getAguardando(){
    this.data1Filtro = '';
    this.data2Filtro = '';
    this.nfservicoService.read('A',this.search, this.currentPage, this.pageSize, this.data1Filtro, this.data2Filtro).subscribe((res: any) => {
      this.nfservicosAguard = res.nfservico;
    });
  }
  getCanceladas(){
    this.data1Filtro = '';
    this.data2Filtro = '';
    this.nfservicoService.read('C',this.search, this.currentPage, this.pageSize, this.data1Filtro, this.data2Filtro).subscribe((res: any) => {
      this.nfservicosCanc = res.nfservico;
    });
  }

  Search() {
     this.getTransmitidas();
  }

  enviarEmail(nfs_id: number, i:number) {
     this.nfservicoService.enviarEmailNFSe(nfs_id,this.nfservicosTrans[i].Entidade.emailBoleto);
  }

  async enviarNFSe(nfs_id: number) {
  
    this.msgSpinner = 'Enviando nota para prefeitura...';
   // this.spinnerService.show();
    await this.nfservicoService.enviarNFSe(nfs_id).then((res: any) => {
  //    this.getAguardando();
 //     alert(res.status);
  //    console.log(res);
  //    if(res.status == 100){
   //       this.getAguardando();
          // Swal.fire({
          //   title:  "",
          //   text: 'e-mail enviado com sucesso.',
          //   icon: 'success',
          // });
 //       } else {
          // Swal.fire({
          //   title:  "Ops!",
          //   text: res.msg,
          //   icon: 'error',
          // }); 
  //      };
 //       alert(res.msg);
      });
   //  this.spinnerService.hide();

  }

  print(nfs_id: number) {
    this.nfservicoService.imprimirNFSe(nfs_id); 
  }

  openDialog(nfs_id: number) {
    const dialogRef = this.dialog.open(ConfirmationDialog,{
      data:{
        message: 'Confirma o envio de e-mail?',
        buttonText: {
          yes: 'Sim',
          no: 'Não'
        }
      }
    });
    
    // dialogRef.afterClosed().subscribe((confirmed: boolean) => {
    //   if (confirmed) { this.enviarEmail(nfs_id,i)
    //   }
    // });
  }

  onChangeSelect(event) {
    this.data1Filtro = '';
    this.data2Filtro = '';

    event.value == "Todos" ?
    this.enabledDatePicker = false : 
    this.enabledDatePicker = true;
  }

  onChangeFirstDatePicker(event) {
    this.data1Filtro = this.formatData(event);
  }
  
  onChangeSecDatePicker(event) {
    this.data2Filtro = this.formatData(event);

  }

  formatData(event) {
    let arr = [
      event.value.getFullYear(),
      event.value.getMonth() + 1,
      event.value.getDate()
    ];

    for (let i = 0; i < 3; i++) {

      if (arr[i] >= 10) {
        arr[i] = arr[i].toString();

      } else {
        arr[i] = "0" + arr[i].toString();
      }
    } let dateStr = arr.join('-');
    return dateStr;
  }

  filtrarValores() {
    console.log(this.data1Filtro );
    console.log(this.data2Filtro );
   this.getTransmitidas();
  }
  


}
export class ConfirmationDialog {}
export class ProgressSpinnerOverviewExample {}
