import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoginService } from 'src/app/services/login.service';



// $(document).ready(function() {
//   $('.js-example-basic-single').select2();
// });


// $('.js-data-example-ajax').select2({
//   ajax: {
//     url: 'http://cpro28883.publiccloud.com.br:8085/entidade',
//     dataType: 'json'
//     // Additional AJAX parameters go here; see the end of this chapter for the full code of this example
//   }
// });

@Component({
  selector: 'app-modal-clientes-teste',
  templateUrl: './modal-clientes-teste.component.html',
  styleUrls: ['./modal-clientes-teste.component.css']
})
export class ModalClientesTesteComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ModalClientesTesteComponent>,) {
    

   }
   placeDestinatario: string;
   



  ngOnInit(): void {
    this.placeDestinatario = 'Pesquise o destinatário...';

  }


  fecharDialog() {
    this.dialogRef.close(false);
  }

//   searchDestinatario() {
//     $('#select2Cliente').select2({
//       ajax: {
//         url: 'http://cpro28883.publiccloud.com.br:8085/entidade',
//         data: function (params) {
//           var query = {
//             search: params.term,
//             type: 'public'
//           }
    
//           // Query parameters will be ?search=[term]&type=public
//           return query;
//         }
//       }
//     });
// }
  
  // searchDestinatario() {
  //   var self = this;
  //   $('#select2Cliente').select2({
  //     placeholder: 'teste teste',
  //     minimumInputLength: 2,
  //     ajax: {
  //       headers: {  },
  //       url: 'http://cpro28883.publiccloud.com.br:8085/entidade',
  //       type: 'get',
  //       dataType:"json",
  //       delay: 250,
  //         data: function (params) {
  //           return {
  //             page: 0,
  //             pageSize: 50,
              // status: 'A',
              // tipo: 'C',
          //     search: params.term,
          //   };
          // },
          // async: true,
          // error: function (xhr, error, code){},

          // processResults: function (data, params) {
          //   params.page = params.page || 1;

          //   return {
          //     results: $.map(data.data, function (obj) {
          //       return { 
          //          text: obj.nome,
          //          id: obj.id
          //     }
          //     }),
          //     pagination: {
        
          //     }
          //   };
          // },

  //         cache: true
  //       },
  //       dropdownParent: $('#modalClientes'),
  //   });
  // }

//   teste
//   $(document).ready( () {
//     $.ajax({
//         type: "get",
//         url: "/Empresas/SelecionarEmpresas",
//         data: { empresa: $("#empresa").val() },
//         dataType: 'json',
//         contentType: "application/json; charset=utf-8",
//         success: function (obj) {
//             if (obj != null) {
//                 var data = obj.data;
//                 var selectbox = $('#empresa');
//                 selectbox.find('option').remove();
//                 $.each(data, function (i, d) {
//                     $('<option>').val(d.IdEmpresa).text(d.Nome).appendTo(selectbox);
//                 });
//             }
//         }
//     });
// });

// $.ajax({
//   type: 'GET',
//   url: 'torneio',
//   success: function(data) {
//           // Adiciona a primeira linha.
//           $("#selectTorneios").append($('<option>', {
//               value: -1,
//               text: 'Selecione um torneio'
//           }));
         
//           torneios = JSON.parse(data);
         
//           // Adiciona as demais linhas (dinâmico).
//           $.each(torneios, function(id, torneio) {
//               $("#selectTorneios").append($('<option>', {
//                   value: torneio.id,
//                   text: torneio.nome
//               }));
//           });
//   }
// });


}
