import { Injectable } from '@angular/core';
import { MatSnackBar } from "@angular/material/snack-bar";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { GrupoEmpresa } from  '../models/grupoempresa.model';

import { DataService } from './data.service';
import { Observable, EMPTY } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { ClienteContabil } from '../models/contabilidade.model';

@Injectable({
  providedIn: 'root'
})
export class GrupoEmpresaService {

  constructor(private service: DataService, private snackBar: MatSnackBar, private http: HttpClient) { }
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'})
  }
  getByCliente(idCliente: number): Observable<GrupoEmpresa[]> {
    console.log(idCliente);
    return this.http.get<GrupoEmpresa[]>(this.service.baseUrl+'/GrupoEmpresa?idCliente='+idCliente, this.httpOptions).pipe(
      map((obj) => obj),
      catchError((e) => this.errorHandler(e))
    );
  }
  errorHandler(e: any): Observable<any> {
    this.service.showMessage("Ocorreu um erro!", true);
    return EMPTY;
  }
  getByClienteContabil(idCliente: number, search): Observable<ClienteContabil[]> {
    return this.http.get<GrupoEmpresa[]>(this.service.baseUrl+'/GrupoEmpresa?idPri='+ idCliente + '&search='+ search, this.httpOptions).pipe(
      map((obj) => obj),
      catchError((e) => this.errorHandler(e))
    );
  }
  createClienteContabil(clienteContabil: ClienteContabil): Observable<ClienteContabil> {
    return this.http.post<ClienteContabil>(this.service.baseUrl + '/grupoempresa', clienteContabil, this.httpOptions);
  }
  deleteClienteContabil(id: number){
    const url = `${this.service.baseUrl + '/grupoempresa'}/${id}`;
    return this.http.delete(url).pipe(
      map((obj) => obj),
      catchError((e) => this.errorHandler(e))
    );
  }
}
