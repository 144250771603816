import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs/internal/Subscription';
import { ClienteService } from 'src/app/services/cliente.service';

@Component({
  selector: 'app-modal-chave-acesso',
  templateUrl: './modal-chave-acesso.component.html',
  styleUrls: ['./modal-chave-acesso.component.scss']
})
export class ModalChaveAcessoComponent implements OnInit {

  public postSubscription: Subscription;
  public dateStr: string;
  public form: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<ModalChaveAcessoComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public snackBar: MatSnackBar,
    private fb: FormBuilder,
    private _clienteService: ClienteService
  ) {
    this.form = this.fb.group({
      dateIni:[
        "",
        Validators.compose([Validators.required])
      ]
    });
  }

  ngOnInit(): void {

  }

  formatData(event) {
    let dateStr = ''
    let arr = [
      event.value.getFullYear(),
      event.value.getMonth() + 1,
      event.value.getDate()
    ];

    for (let i = 0; i < 3; i++) {

      if (arr[i] >= 10) {
        arr[i] = arr[i].toString();

      } else {
        arr[i] = "0" + arr[i].toString();
      }
    } dateStr = arr.join('-');
    dateStr += 'T23:59:59';
    this.dateStr = dateStr;
  }

  gerarChave() {
    let autohide = 3000;
    let data = {
      "id": this.data.id,
      "cnpj": this.data.cnpj,
      "dataValidade": this.dateStr
    }
    this.postSubscription = this._clienteService.createClienteChaveAcesso(data)
      .subscribe((res: any) => {
        this.snackBar.open('Chave gerada', undefined, {
          duration: autohide,
          panelClass: ['ok-snackbar']
        });
        this.enviarChaveDialog(res);
      })
  }
  enviarChaveDialog(data) {
    this.dialogRef.close(data);
  }

  fecharDialog() {
    this.dialogRef.close(false);
  }
}
