import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { ClienteService } from 'src/app/services/cliente.service';
import { DialogService } from 'src/app/services/dialog.service';
import { GrupoEmpresaService } from 'src/app/services/grupoempresa.service';

@Component({
  selector: 'app-list-grupo-empresa',
  templateUrl: './list-grupo-empresa.component.html',
  styleUrls: ['./list-grupo-empresa.component.scss']
})
export class ListGrupoEmpresaComponent implements OnInit {

  public getSubscription: Subscription;
  public dialogSubscription: Subscription;

  displayedColumnsAtivos = ['id', 'nome', 'razao','status' , 'acao'];
  objAtivos: any[];
  idGrupo:number[];

  constructor(
    private _clienteService: ClienteService,
    public dialogRef: MatDialogRef<ListGrupoEmpresaComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public _dialogService: DialogService,
    private _grupoEmpresaService: GrupoEmpresaService,
    public snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.getEmpresa();
  }

  getEmpresa(){
    this.getSubscription = this._clienteService.readGrupoEmpresa(this.data.id)
    .subscribe((res: any) => {
      let objAtivos = res.grupoEmpresa;
      this.idGrupo = res.grupoEmpresa.map(x => x.id);
      this.objAtivos = objAtivos.map(x => x.EntidadeSecundario);
    })
  }

  vincular() {
    this.dialogSubscription = this._dialogService
      .vinculaClienteEscritorio(this.data.id, 'EM')
      .afterClosed()
      .subscribe(res => {this.getEmpresa();});
  }
  desvincular(index){
    let autohide = 3000;
    let botao = "desvincular";
    let msg = "Deseja desvincular empresa?";
    this._dialogService
      .openConfirmDialog(msg, botao)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.getSubscription = this._grupoEmpresaService.deleteClienteContabil(this.idGrupo[index])
          .subscribe((res:any)=>{
            this.snackBar.open(res.message, undefined, {
              duration: autohide,
              panelClass: ['ok-snackbar']
            });
            this.getEmpresa();
          });
        }
      });
  }

  fecharDialog() {
    this.dialogRef.close(false);
  }
}
