import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ContabilidadeService } from 'src/app/services/contabilidade.service';

@Component({
  selector: 'app-procura-cpf-cnpj',
  templateUrl: './procura-cpf-cnpj.component.html',
  styleUrls: ['./procura-cpf-cnpj.component.scss']
})
export class ProcuraCpfCnpjComponent implements OnInit, OnDestroy {
  
  public form: FormGroup;
  public getSubscription: Subscription;

  constructor(private _fb: FormBuilder,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ProcuraCpfCnpjComponent>,
    private router: Router,
    private _contabilidadeService: ContabilidadeService) { 
    this.form = this._fb.group({
      cnpjcpf: ["", Validators.compose([Validators.minLength(1)])]
    });   
  }
  ngOnInit(): void {
  }

  submit(){
    let autohide = 3000;
    this.getSubscription = this._contabilidadeService
    .findCPFCNPJ(this.form.controls.cnpjcpf.value).subscribe((res:any)=>{
      if(res.entidade.length > 0 ){
        this.snackBar.open("Registro já cadastrado.", undefined, {
          duration: autohide,
          horizontalPosition: "center",
          verticalPosition: "top",
          panelClass: ['ok-snackbar']
        });
      } else {
        this.fecharDialog();
      }
    })   
  }

  ngOnDestroy(): void {
    if (this.getSubscription) {
      this.getSubscription.unsubscribe();
    }
  }

  
  fecharDialog() {
    this.dialogRef.close(this.form.controls.cnpjcpf.value);
  }
  voltar(){
    this.dialogRef.close(false);
    this.router.navigateByUrl("/contabilidade");
  }
}
