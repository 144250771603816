import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '../models/usuario.model';
import { DataService } from './data.service';

export class Search {

    public static set(pesquisa:string, status:string) {
      sessionStorage.setItem("kironADMsearch", pesquisa);
      sessionStorage.setItem("kironADMstatus", status);
    }
  
    public static setSearch(pesquisa) {
        sessionStorage.setItem("kironADMsearch", pesquisa);
      }
      public static setStatus(status:string) {
        sessionStorage.setItem("kironADMstatus", status);
      }
      
    public static getSearch() {
        const search = sessionStorage.getItem("kironADMsearch");
      if (search) {
        return search;
      } else {
        return null;
      }
    }
  
    public static getStatus() {
        const search = sessionStorage.getItem("kironADMstatus");
      if (search) {
        return search;
      } else {
        return null;
      }
    }
    public static hasSearch(): boolean { 
      if (this.getSearch()) return true;
      else return false;
    }
    public static hasStatus(): boolean { 
        if (this.getStatus()) return true;
        else return false;
      }

  
    public static clear() {
      sessionStorage.removeItem("kironADMsearch");
      sessionStorage.removeItem("kironADMstatus");
    }
  }
  