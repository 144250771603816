<mat-card class="card-dados">
    <h1 class="title" *ngIf="data.tipo === 'E'" >Pesquisar Cliente </h1>
    <h1 class="title" *ngIf="data.tipo === 'C'" >Pesquisar Escritório </h1>
    <form [formGroup]="form" class="search-form">
        <mat-form-field class="inputLarge">
          <mat-label>
            Pesquisar nome ou CNPJ
          </mat-label>
          <input [matAutocomplete]="auto"
          formControlName="cnpjcpf" matInput  maxlength="17" >
          <button mat-button matSuffix mat-icon-button>
            <mat-icon (click)="consulta()">search</mat-icon>
          </button>
        </mat-form-field>
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let option of cliente" 
          (onSelectionChange)="inserirId(option.id)" [value]="option.nome">
              {{option.nome}}
          </mat-option>
      </mat-autocomplete>
      </form>
      <div *ngIf="clienteExiste" class="info">
        <p>
        {{cliente.nome}}
      </p>
      <p>
        {{cliente.razao}}
      </p>
      <p>
        {{cliente.cnpj}}
      </p>
      </div>
      
      <button class="botaoSecundario" mat-raised-button style="margin-right:15px;" (click)="fecharDialog()">
        Voltar
      </button>
      <button class="botaoPrimario" [disabled]='clienteExiste==false' mat-raised-button (click)="vincular()">
       <span *ngIf="data.tipo === 'E' || data.tipo === 'U'" >Vincular Cliente</span>
       <span *ngIf="data.tipo === 'C'">Vincular Escritório</span>
       <span *ngIf="data.tipo === 'EM'">Vincular Empresa</span>
      </button>
</mat-card>
