import { Component, OnDestroy, OnInit } from '@angular/core';
import { ClienteService } from '../../../services/cliente.service';
import { NfservicoService } from '../../../services/nfservico.service';
import { UsuarioEntidadeService } from '../../../services/usuarioentidade.service';
import { GrupoEmpresaService } from '../../../services/grupoempresa.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { ChargesService } from '../../../services/vindi.charges.service';
import { DataService } from '../../../services/data.service';
import { Cliente } from '../../../models/cliente.model';
import { NFServico } from '../../../models/nfservico.model';
import { Charges } from '../../../models/vindi.charges.model';
import { Router, ActivatedRoute } from '@angular/router';
import { UsuarioEntidade } from 'src/app/models/usuarioentidade.model';
import { GrupoEmpresa } from 'src/app/models/grupoempresa.model';
import { Subscription } from 'rxjs';
import { DialogService } from 'src/app/services/dialog.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContabilidadeService } from 'src/app/services/contabilidade.service';
import { CustomersService } from '../../../services/vindi.customers.service';
import { Customers, Metadata} from '../../../models/vindi.customers.model';


interface Plano {
  id: number;
  nome: string;
}

interface Pagto {
  id: number;
  nome: string;
}

interface ChaveAcesso {
  chave: string;
  dataValidade: string;
}

declare var $:any;

@Component({
  selector: 'app-info-cliente',
  templateUrl: './info-cliente.component.html',
  styleUrls: ['./info-cliente.component.scss']
})
export class ClienteInfoComponent implements OnInit, OnDestroy {
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  pctAcessoCtrl = new FormControl();
  isRespFinanceiro: string = "N";  
  customer = {} as Customers;
  metadata = {} as Metadata;

  idGrupo: number[];

  @ViewChild('Input') Input: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  cliente: Cliente;

  chave: ChaveAcesso;

  getSubscription: Subscription;
  postSubscription: Subscription;
  delSubscription: Subscription;
  dialogSubscription: Subscription;
  putSubscription: Subscription;

  public dateGroup: FormGroup;
  public FinDateGroup: FormGroup;

  pctAcesso: any;
  lstPctAcesso: any[];
  lstPro: any[];

  objAtivos: any[];
  displayedColumnsAtivos = ['data', 'vAntigo', 'valor', 'indice'];

  objNotServ: any[];
  displayedColumnsNotServ = ['total', 'media'];

  objNotVindi: any[];
  displayedColumnsNotVindi = ['total', 'media'];

  objCont: any[];
  displayedColumnsCont = ['emailCont', 'contatoCont'];

  nfservicos: NFServico[];
  displayedColumns = ['id', 'dtEmissao', 'emailBoleto', 'numeroNota', 'vServico', 'status', 'dtEmail', 'razao', 'actions'];

  usuarios: UsuarioEntidade[];
  displayedColumnsUsuarios = ['id', 'nome', 'login', 'dtInclusao', 'status', 'actions'];

  grupos: GrupoEmpresa[];
  displayedColumnsGrupos = ['id', 'nome', 'actions'];

  charges: Charges[];
  displayedColumnsCharges = ['created_at', 'due_at', 'amount', 'status'];



  constructor(private clienteService: ClienteService,
    private _contabilidadeService: ContabilidadeService,
    private dataService: DataService,
    private _dialogService: DialogService,
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private nfservicoService: NfservicoService,
    private chargesService: ChargesService,
    private usuariosService: UsuarioEntidadeService,
    private gruposService: GrupoEmpresaService,
    public snackBar: MatSnackBar,
    private customersService: CustomersService,

  ) {
    this.dateGroup = this.fb.group({
      dataSelect: '',
      dateIni: '',
      dateFin: ''
    });
    this.FinDateGroup = this.fb.group({
      dataSelect: '',
      dateIni: '',
      dateFin: ''
    });
  }
  ngOnDestroy(): void {
  }

  selectedValue: string;
  planos: Plano[] = [
    { id: 1, nome: 'Kiron NFe' },
    { id: 2, nome: 'Kiron Controle' },
    { id: 3, nome: 'Portal' },
  ];
  pagtos: Pagto[] = [
    { id: 1, nome: 'Boleto' },
    { id: 2, nome: 'Cartão de crédito' }
  ];

  status: any[] = [
    { status: 'paid', trad: 'pago' },
    { status: 'canceled', trad: 'cancelado' },
    { status: 'pending', trad: 'pendente' },
  ];

  ngOnInit(): void {
    const id = +this.route.snapshot.paramMap.get("id");
    this.clienteService.readById(id).subscribe((res: any) => {
      
      this.cliente = res.entidade;
      // alert(this.cliente.isRespFinanceiro);
      if(this.cliente.isRespFinanceiro == 1){
        this.isRespFinanceiro =  "S"
      }
      else{
        this.isRespFinanceiro =  "N"
      };
      
      this.objCont = res.entidade;
      this.getPctAcesso();
      this.getReajuste();
      this.cliente.contatoCont ? this.vincDisabled = true : this.vincDisabled = false;
    });
  }


  save(): void {
    if(this.isRespFinanceiro == "S"){
      this.cliente.isRespFinanceiro = 1;
    }
    else{
      this.cliente.isRespFinanceiro = 0;
    };
    // const dateContrato = $('#dtContrato').val()+'T07:00:00';

    this.cliente.dtContrato = new Date($('#dtContrato').val()+'T07:00:00');
    // this.cliente.dtContrato = new Date($('#dtContrato').val());

    // this.cliente.dtContrato = new Date('1995-12-17T03:24:00');
    // alert(this.cliente.dtContrato)
    // alert($('#dtContrato').val())
    // console.log(this.dtContrato)
    // this.cliente.dtContrato = $('#dtContrato').val()+'T07:00:00';


    // this.cliente.dtContrato = $('#dtContrato').val();

    if(this.cliente.idVindi != "0") {
      this.customer.email = '';
      this.metadata._cc_email = '';
      if(this.cliente.emailBoleto != '' ) {
        this.separarEmail();
      }
      this.customer.notes  = this.cliente.obs;
      this.customersService.update(this.cliente.idVindi,this.customer).subscribe(() => {});
    }
    this.clienteService.update(this.cliente).subscribe(() => {
      this.dataService.showMessage('Cliente alterado com sucesso!')
    });
    this.router.navigate(['/clientes']);
  }

  // aguaminernovavida10@gmail.com;aguaminernovavida@ig.com.br
  //thiago.silva@foccoconstrucoes.com.br;financeiro@foccoconstrucoes.com.br;contato@kiron.net.br

async separarEmail() {
  var emailAux, ccEmail: string;
  var i: number;
  // var emailPrincipal: string = this.cliente.emailBoleto.replace(";",",");
  var emailPrincipal: string = this.cliente.emailBoleto.split(";").join(",");
  i = emailPrincipal.indexOf(",");
  
  if( i > -1 ){
    emailAux = emailPrincipal.substring(0,i);
    this.customer.email = emailAux;
    ccEmail = emailPrincipal.substring(i+1);
    this.metadata._cc_email = ccEmail;
    this.customer.metadata = this.metadata;
  } else {
    this.customer.email = this.cliente.emailBoleto;
  }
};

  cancel(): void {
    this.router.navigate(['/clientes'])
  }

  getCliente() {
    this.clienteService.readById(this.cliente.id).subscribe((res: any) => {
      this.cliente = res.entidade;
      this.objCont = res.entidade;
      this.cliente.contatoCont ? this.vincDisabled = true : this.vincDisabled = false;
    });
  }

  getNotas() {
    this.nfservicoService.readByCliente(this.cliente.id).subscribe((res: any) => {
      this.nfservicos = res.nfservico;
      this.objNotServ = res.sum;
      this.objNotServ[0].media = this.objNotServ[0].media.toFixed(2);
      this.objNotServ[0].total = this.objNotServ[0].total.toFixed(2);
    });
  }

  enviarEmail(nfs_id: number) {
    this.nfservicoService.enviarEmailNFSe(nfs_id,'');
  }

  print(nfs_id: number) {
    this.nfservicoService.imprimirNFSe(nfs_id);
  }

  getFinanceiro() {
    this.chargesService.getChargesByCliente(this.cliente.idVindi).subscribe(charges => {
      this.charges = charges;
      this.objNotVindi = charges;
      charges.map(x =>
        this.status.map(s => {
          if (s.status == x.status)
            x.status = s.trad;
        })
      );
      let amount = charges.map(x => Number(x.amount));
      let total = amount.reduce((a, b) => a + b, 0);
      let media = total / (amount.length);
      let sum = [{ total: total.toFixed(2), media: media.toFixed(2) }]
      this.objNotVindi = sum;
    });
  }

  arrChecked: any[] = [0];
  getPortal() {
    this.usuariosService.getByCliente(this.cliente.id).subscribe((res: any) => {
      this.usuarios = res.usuarioEntidade.map((x, index) => {
        x.isAtivo == 'S' ? this.arrChecked[index] = true : this.arrChecked[index] = false;
        return x;
      });
      this.idGrupo = res.usuarioEntidade.map(x => x.id);
    });

  }

  getPctAcesso() {
    this.getSubscription = this.clienteService.readPctAcesso(this.cliente.id)
      .subscribe((res: any) => {
        this.pctAcesso = res.entidadePctacesso;
        this.lstPctAcesso = res.produto;
        this.lstPro = this.pctAcesso.map(x => x.Produto);
      });
  }

  getReajuste() {
    this.getSubscription = this.clienteService.readReajuste(this.cliente.id)
      .subscribe((res: any) => {
        this.objAtivos = res.entidadeReajuste;
      });
  }

  getNfs() {
    this.getSubscription = this.clienteService.readReajuste(this.cliente.id)
      .subscribe((res: any) => {
        this.objAtivos = res.entidadeReajuste;
      });
  }

  entidadeEsc: any;
  temEscritorio: boolean;

  getGerais() {
    this.getSubscription = this.clienteService.readClienteEscritorio(this.cliente.id)
      .subscribe((entidadeEsc: any) => {
        this.entidadeEsc = entidadeEsc.entidadeEscritorio[0];

        this.entidadeEsc.Escritorio != undefined ?
          this.temEscritorio = true : this.temEscritorio = false;
      });
  }

  abrirGrupoEmpresa() {
    this._dialogService
      .visualizarGrupoEmpresas(this.cliente.id)
      .afterClosed()
      .subscribe();
  }

  bloquearAcesso(id: number) {
  }

  public autohide = 3000;

  resetarSenha(id: number) {
    this.putSubscription = this._contabilidadeService.resetPass(id)
      .subscribe((res: any) => {
        this.snackBar.open(res.message, undefined, {
          duration: this.autohide,
          panelClass: ['ok-snackbar']
        });
      });
  }

  desvincular(index: number) {
    let botao = "desvincular";
    let msg = "Deseja desvincular usuário?";
    this._dialogService
      .openConfirmDialog(msg, botao)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.getSubscription = this._contabilidadeService.delClienteUserRelation(this.idGrupo[index])
            .subscribe((res: any) => {
              this.snackBar.open(res.message, undefined, {
                duration: this.autohide,
                panelClass: ['ok-snackbar']
              });

            });
        }
      });
    this.getPortal();

  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.lstPro.push(value.trim());
    }

    if (input) {
      input.value = '';
    }
    this.pctAcessoCtrl.setValue(null);
  }

  remove(produto): void {
    const index = this.lstPro.indexOf(produto);

    let pacoteId = this.pctAcesso.filter(x => { if (x.Produto.id == produto.id) return x.id; else return 0; });
    if (index >= 0) {
      this.lstPro.splice(index, 1);
    }
    this.delSubscription = this.clienteService.deletePctAcesso(pacoteId[0].id)
      .subscribe((res: any) => {
      });
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.lstPro.push(event.option.value);

    let val: any = event.option.value;

    let data = {
      "idEntidade": this.cliente.id,
      "idProduto": val.id
    }
    this.postSubscription = this.clienteService.createPctAcesso(data).subscribe();
    this.Input.nativeElement.value = '';
    this.pctAcessoCtrl.setValue(null);
  }

  enabledDatePicker: boolean = false;
  defaultValue = "Todos";

  onChangeSelect(event) {
    event.value == "Todos" ? this.enabledDatePicker = false : this.enabledDatePicker = true;
  }

  data1Filtro: string;
  data2Filtro: string;

  onChangeFirstDatePicker(event) {
    this.data1Filtro = this.formatData(event);
  }

  onChangeSecDatePicker(event) {
    this.data2Filtro = this.formatData(event);

  }

  formatData(event) {
    let arr = [
      event.value.getFullYear(),
      event.value.getMonth() + 1,
      event.value.getDate()
    ];

    for (let i = 0; i < 3; i++) {

      if (arr[i] >= 10) {
        arr[i] = arr[i].toString();

      } else {
        arr[i] = "0" + arr[i].toString();
      }
    } let dateStr = arr.join('-');
    return dateStr;
  }

  filtrarValores() {
    if (this.enabledDatePicker == false) {
      this.getSubscription = this.clienteService
        .readNfs(this.cliente.id)
        .subscribe((res: any) => {
          this.objNotServ = res.sum;
          this.objNotServ[0].media = this.objNotServ[0].media.toFixed(2);
          this.objNotServ[0].total = this.objNotServ[0].total.toFixed(2);
          this.nfservicos = res.nfservico;
        });
    } else {
      this.getSubscription = this.clienteService
        .readNfsByDate(this.cliente.id, this.data1Filtro, this.data2Filtro)
        .subscribe((res: any) => {
          this.objNotServ = res.sum;
          this.nfservicos = res.nfservico;
        });
    }
  }

  vincDisabled: boolean = true;
  vincularEscritorio() {
    this.dialogSubscription = this._dialogService
      .vinculaClienteEscritorio(this.cliente.id, 'C')
      .afterClosed()
      .subscribe((res: any) => this.getCliente());
  }

  desvincularEscritorio() {

    let data = {
      "status": 2
    };
    let autohide = 3000;
    let msg = "Deseja desvincular Escritório?";
    let botao = "desvincular";
    this._dialogService
      .openConfirmDialog(msg, botao)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.putSubscription = this.clienteService.updateClienteEscritorio(data, this.entidadeEsc.id)
            .subscribe((res: any) => {
              this.snackBar.open(res.message, undefined, {
                duration: autohide,
                panelClass: ['ok-snackbar']
              });
              this.getGerais();
            });
        }
      });
  }

  tabClick(tab) {
    let index = tab.index;
    if (index == 1) {
      this.getNotas();
    } else if (index == 2) {
      this.getFinanceiro();
    } else if (index == 3) {
      this.getPortal();
    } else if (index == 4) {
      this.getGerais();
    }

  }

  gerarChave() {
    this._dialogService.gerarChaveACesso(this.cliente.id, this.cliente.cnpj)
      .afterClosed().subscribe(
        data => {
          this.cliente.chaveAcesso = data.chave;
          this.cliente.dtValidaChave = data.dataValidade + ".000Z";
        });
  }

  vincularUsuario(acao, idUsuario) {
    this.dialogSubscription = this._dialogService.gerenciarUsuario(this.cliente.id, acao, idUsuario)
      .afterClosed()
      .subscribe((res: any) => this.getPortal());
  }

  // USUARIOS
  color = 'accent';
  checked = false;
  changed(event, index) {

    event.checked === true ?
      this.reativarUsuario(index) :
      this.desvincular(index);
  }

  reativarUsuario(index) {
    this.getSubscription = this._contabilidadeService.reativarClienteUserRelation(this.idGrupo[index])
      .subscribe((res: any) => {
        this.snackBar.open(res.message, undefined, {
          duration: this.autohide,
          panelClass: ['ok-snackbar']
        });
      });
  }


  //VINDI --> ABA FINANCEIRO

  filtrarValoresVINDI() {

    this.getSubscription = this.chargesService.getChargesByPaid(this.cliente.idVindi, this.data1Filtro, this.data2Filtro + 'T23:59:59')
      .subscribe((res: any) => {
        this.charges = res;
        let amount = res.map(x => Number(x.amount));
        let total = amount.reduce((a, b) => a + b, 0);
        let media = total / (amount.length);
        let sum = [{ total: total.toFixed(2), media: media.toFixed(2) }]
        this.objNotVindi = sum;

      }
      );
  }

}    