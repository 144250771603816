import { Injectable } from '@angular/core';
import { MatSnackBar } from "@angular/material/snack-bar";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Contabilidade } from '../models/contabilidade.model';
import { Observable, EMPTY } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class ContabilidadeService {

  constructor(private service: DataService, private http: HttpClient) { }
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  }
  totalActive = 0;
  totalInactive = 0;

  create(contabilidade: Contabilidade): Observable<Contabilidade> {
    return this.http.post<Contabilidade>(this.service.baseUrl + '/entidade', contabilidade, this.httpOptions);
  }

  read(status: String, search: String, page: number, sizepage: number): Observable<Contabilidade[]> {
    let searchLocal: String = '';
    if (search != '') {
      searchLocal = '&search=' + search;
    };
    return this.http.get<Contabilidade[]>(this.service.baseUrl + '/entidade?status=' + status +
      "&isEscritorio=S" +
      searchLocal +
      '&page=' + page +
      '&pageSize=' + sizepage,
      this.httpOptions);
  }

  readById(id: number): Observable<Contabilidade> {
    return this.http.get<Contabilidade>(this.service.baseUrl + '/entidade/' + id, this.httpOptions);

  }

  findCPFCNPJ(docNumber: number): Observable<Contabilidade> {
    return this.http.get<Contabilidade>(this.service.baseUrl + '/entidade?cnpjcpf=' + docNumber, this.httpOptions);

  }

  update(contabilidade: Contabilidade, id): Observable<Contabilidade> {
    const url = `${this.service.baseUrl}/entidade/${id}`;
    return this.http.put<Contabilidade>(url, contabilidade, this.httpOptions).pipe(
      map((obj) => obj),
      catchError((e) => this.errorHandler(e))
    );
  }

  delete(id: number): Observable<Contabilidade> {
    const url = `${this.service.baseUrl}/${id}`;
    return this.http.delete<Contabilidade>(url, this.httpOptions).pipe(
      map((obj) => obj),
      catchError((e) => this.errorHandler(e))
    );
  }

  errorHandler(e: any): Observable<any> {
    this.service.showMessage("Ocorreu um erro!", true);
    return EMPTY;
  }

  // USUARIOS 

  readByIdUser(id: number, search:String){
    return this.http.get(this.service.baseUrl + '/usuarioentidade?idCliente=' + id + '&search='+ search, this.httpOptions);
  }

  criarUsuarioContabil(usuario){
    return this.http.post(this.service.baseUrl + '/usuarioentidade', usuario, this.httpOptions);
  }

  readUserSearch(email: string){
    return this.http.get(this.service.baseUrl + '/usuario?status=A&search=' + email, this.httpOptions);
  }
  
  readAllUseSearch(status: String, search: String, page: number, sizepage: number){
    let searchLocal: String = '';
    if (search != '') {
      searchLocal = '&search=' + search;
    };
    return this.http.get(this.service.baseUrl + '/usuario?status=' + status +
      searchLocal +
      '&page=' + page +
      '&pageSize=' + sizepage, this.httpOptions);
  }

  readClientsByUser(idUsuario: number){ 
    return this.http.get(this.service.baseUrl + '/usuarioentidade?idUsuario=' + idUsuario, this.httpOptions);
  }

  delClienteUserRelation(id: number){
    let data = {
      "isAtivo" : "N" 
    }
    const url = `${this.service.baseUrl + '/usuarioentidade'}/${id}`;
    return this.http.put(url, data, this.httpOptions).pipe(
      map((obj) => obj),
      catchError((e) => this.errorHandler(e))
    );
  }
  reativarClienteUserRelation(id: number){
    let data = {
      "isAtivo" : "S" 
    }
    const url = `${this.service.baseUrl + '/usuarioentidade'}/${id}`;
    return this.http.put(url, data, this.httpOptions).pipe(
      map((obj) => obj),
      catchError((e) => this.errorHandler(e))
    );
  }
 
    resetPass(id) {
    let data = {
      "senha": ""
      }
  
    return this.http
      .put(`${this.service.baseUrl}/usuario/${id}`, data, this.httpOptions)
      // .put(`${this.service.baseUrl}/usuario/1439`, data, {})
  }

  
  desativarContabil(id: number){
    let data = {
      "isAtivo" : "N" 
    }
    const url = `${this.service.baseUrl + '/entidade'}/${id}`;
    return this.http.put(url, data, this.httpOptions).pipe(
      map((obj) => obj),
      catchError((e) => this.errorHandler(e))
    );
  }

  reativarContabil(id: number){
    let data = {
      "isAtivo" : "S" 
    }
    const url = `${this.service.baseUrl + '/entidade'}/${id}`;
    return this.http.put(url, data, this.httpOptions).pipe(
      map((obj) => obj),
      catchError((e) => this.errorHandler(e))
    );
  }

}
