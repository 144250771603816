<br><br><br>
<mat-card class="card-dados">
    <div class="row">
        <div class="col-9">
            <h1 *ngIf="id == 0" class="title">Novo Cliente</h1>
            <h1 *ngIf="id > 0" class="title">Editar Cliente</h1>
        </div>
        <div class="col-3">
            <mat-slide-toggle [(ngModel)]="isAtivo" class="example-margin" [color]="color" (change)="changed($event )">
                <span *ngIf="isAtivo === true ">Ativo</span>
                <span *ngIf="isAtivo === false">Inativo</span>
            </mat-slide-toggle>
        </div>
    </div>
    <form>
        <!-- <div class="row"> -->
            <div class="dado">
                <mat-form-field class="cnpj" appearance="outline">
                    <mat-label>CNPJ</mat-label>
                    <input matInput placeholder="CNPJ" [(ngModel)]="cliente.cnpj" name="cnpj">
                    <!-- <mat-icon matSuffix>mode_edit</mat-icon> -->
                    <!-- <button mat-button matSuffix mat-icon-button (click)="consultaCNPJ(cliente.cnpj)">
                <mat-icon>mode_edit</mat-icon>
              </button> -->
                </mat-form-field>
                <mat-form-field class="ie" appearance="outline">
                    <mat-label>Inscrição Estadual</mat-label>
                    <input matInput [(ngModel)]="cliente.ie" placeholder="Inscrição Estadual" name="ie" id="ie">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Cliente responsável financeiro?</mat-label>
                    <mat-select name="isRespFinanceiro" [(ngModel)]="isRespFinanceiro">
                      <mat-option value="S">
                        Sim
                      </mat-option>
                      <mat-option value="N">
                        Não
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
          
            </div>

        <!-- </div> -->
        <div class="dado">
            <mat-form-field appearance="outline" class="nome">
                <mat-label>Nome</mat-label>
                <input matInput placeholder="Nome" [(ngModel)]="cliente.nome" name="nome">
            </mat-form-field>

        </div>
        <div class="dado">
           
            <mat-form-field appearance="outline" class="razao">
                <mat-label>Razão Social</mat-label>
                <input matInput placeholder="Razão Social" [(ngModel)]="cliente.razao" name="razao">
            </mat-form-field>
        </div>
        <div class="dado">
            <mat-form-field class="cep" appearance="outline">
                <mat-label>CEP</mat-label>
                <input matInput maxlength="9" placeholder="Cep" [(ngModel)]="cliente.cep" name="cep" (blur)="consultaCEP($event.target.value)">
            </mat-form-field>
            <mat-form-field appearance="outline" class="rua">
                <mat-label>Endereço</mat-label>
                <input matInput placeholder="Endereço" [(ngModel)]="cliente.endereco" name="endereco">
            </mat-form-field>
            <mat-form-field class="nro" appearance="outline">
                <mat-label>Número</mat-label>
                <input matInput placeholder="Número" [(ngModel)]="cliente.enderecoNumero" name="nro">
            </mat-form-field>
        </div>
        <div class="dado">
            <mat-form-field appearance="outline" class="comple">
                <mat-label>Complemento</mat-label>
                <input matInput placeholder="Complemento" [(ngModel)]="cliente.complemento" name="complemento">
            </mat-form-field>
            <mat-form-field class="bairro" appearance="outline">
                <mat-label>Bairro</mat-label>
                <input matInput placeholder="Bairro" [(ngModel)]="cliente.bairro" name="bairro">
            </mat-form-field>
        </div>
        <div class="dado">
            <mat-form-field class="idCidade" appearance="outline">
                <mat-label>ID Cidade</mat-label>
                <input matInput maxlength="9" placeholder="ID Cidade" [(ngModel)]="cliente.idCidade" name="idCidade" id="idCidade" (blur)="consultaCEP($event.target.value)">
            </mat-form-field>
            <mat-form-field class="cidade" appearance="outline">
                <mat-label>Cidade</mat-label>
                <input matInput placeholder="Cidade" [(ngModel)]="cliente.cidade" name="cidade">
            </mat-form-field>
            <mat-form-field class="uf" appearance="outline">
                <mat-label>Estado</mat-label>
                <input matInput maxlength="2" placeholder="UF" [(ngModel)]="cliente.uf" name="uf">
            </mat-form-field>
        </div>
        <div class="dado">
            <mat-form-field class="fone" appearance="outline">
                <mat-label>Fone</mat-label>
                <span matPrefix>+55 &nbsp;</span>
                <input type="tel" matInput placeholder="555-555-1234" [(ngModel)]="cliente.fone" name="fone">

            </mat-form-field>
            <mat-form-field class="contato" appearance="outline">
                <mat-label>Contato</mat-label>
                <input matInput placeholder="Contato" [(ngModel)]="cliente.contato" name="contato">
            </mat-form-field>
        </div>
        <div class="dado">
            <mat-form-field class="emailComercial" appearance="outline">
                <mat-label>Email (Comercial)</mat-label>
                <input matInput placeholder="Ex. pat@example.com" [(ngModel)]="cliente. email" name="emailCom">
                <mat-hint align="start"><strong>Seprar com ponto e vírgula</strong> </mat-hint>
            </mat-form-field>
           
        </div>
    </form>
</mat-card>
<button mat-raised-button (click)="save()" style="background-color: rgb(2, 117, 216); color: rgb(255, 255, 255); float: right;">
    Salvar
</button>
<button mat-raised-button (click)="cancel()" style="float: right;">
    Cancelar
</button>

