<br><br><br>
<mat-card>

    <div class="teste">
        <i class="material-icons">
            lock
        </i>
        <span>
            Fechamento
        </span>
    </div>
    <br>
    <div class="row">
        <mat-form-field appearance="outline">
            <mat-label >Selecionar o período</mat-label>
            <mat-select (selectionChange)="changePeriodo($event.value)">
                <mat-option *ngFor="let periodo of periodos; let i = index" [value]="i">
                    {{ periodo.mes+' / '+ periodo.ano}}
                </mat-option>
               
            </mat-select>
        </mat-form-field>

        <div class="col-12" style="display: block">
            <button class="botaoPrimario" mat-raised-button (click)="filtrar()" style="float: left;">
                Filtrar Período
            </button>
            <button class="botaoPrimario" mat-raised-button (click)="simular()" style="float: right;">
                Sincronizar
            </button>
            <button class="botaoPrimario" mat-raised-button (click)="simular()" style="float: right;">
                Gerar Período
            </button>
        </div>
    </div>
        <div class="example-container mat-elevation-z4">
            <table mat-table [dataSource]="fechamento">
                <ng-container matColumnDef="id" sticky>
                    <th mat-header-cell *matHeaderCellDef style="width: 8%;">#Id</th>
                    <td mat-cell *matCellDef="let row">{{row.id}}</td>
                </ng-container>
                <ng-container matColumnDef="idEntidade">
                    <th mat-header-cell *matHeaderCellDef style="width: 6%;">id Cliente</th>
                    <td mat-cell *matCellDef="let row">{{row.idEntidade}}</td>
                </ng-container>
                <ng-container matColumnDef="nome" style="width: 25%;">
                    <th mat-header-cell *matHeaderCellDef>Nome Cliente</th>
                    <td mat-cell *matCellDef="let row">{{row.nome}}</td>
                </ng-container>
                <ng-container matColumnDef="limiteXml">
                    <th mat-header-cell *matHeaderCellDef>Qtd XML Padrão</th>
                    <td mat-cell *matCellDef="let row">{{row.limiteXml}}</td>
                </ng-container>
                <ng-container matColumnDef="totalConsumidoXml">
                    <th mat-header-cell *matHeaderCellDef>Qtd Consumida</th>
                    <td mat-cell *matCellDef="let row">{{row.totalConsumidoXml}}</td>
                </ng-container>
                <ng-container matColumnDef="vExcedente">
                    <th mat-header-cell *matHeaderCellDef style="width: 10%;">R$ Excedente</th>
                    <td mat-cell *matCellDef="let row">{{row.vExcedente | currency : 'BRL'}}</td>
                </ng-container>
                <ng-container matColumnDef="vMensal">
                    <th mat-header-cell *matHeaderCellDef style="width: 10%;">R$ Mensalidade</th>
                    <td mat-cell *matCellDef="let row">{{row.vMensal | currency : 'BRL'}}</td>
                </ng-container>
                 <ng-container matColumnDef="vTotal">
                    <th mat-header-cell *matHeaderCellDef style="width: 10%;">R$ Total</th>
                    <td mat-cell *matCellDef="let row">{{row.vTotal | currency : 'BRL'}}</td>
                </ng-container>
               
                <ng-container matColumnDef="precoExcedente">
                    <th mat-header-cell *matHeaderCellDef style="width: 10%;">R$ por Excedente</th>
                    <td mat-cell *matCellDef="let row">{{row.precoExcedente | currency : 'BRL'}}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsAtivos"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsAtivos;"></tr>
            </table>
        </div>
        <mat-paginator 
              [length]="lengthAtivos"
              [pageSize]="pageSizeAtivos"
              [pageSizeOptions]="pageSizeOptionsAtivos"
              (page)="pageEvent = clickPageAtivos($event)">
        </mat-paginator>
</mat-card>