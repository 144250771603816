import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Contabilidade } from 'src/app/models/contabilidade.model';
import { ContabilidadeService } from 'src/app/services/contabilidade.service';
import { DialogService } from 'src/app/services/dialog.service';
import { Search } from 'src/app/services/search.service';

@Component({
  selector: 'app-list-contabilidade',
  templateUrl: './list-contabilidade.component.html',
  styleUrls: ['./list-contabilidade.component.scss']
})
export class ListContabilidadeComponent implements OnInit {

  public autohide = 3000;
  getSubscription: Subscription;
  
  objAtivos: Contabilidade[];
  objInativos: Contabilidade[];
  displayedColumnsAtivos = ['id', 'nome', 'razao', 'cnpj', 'cpf','action'];
  displayedColumnsInativos = ['id', 'nome', 'razao', 'cnpj', 'cpf', 'action'];

  constructor(private _contabilidadeService: ContabilidadeService,
    private router: Router,
    private _dialogService: DialogService, 
    public snackBar: MatSnackBar) { 
      this.search = '';
    }
  
    status:string = "A";
  selectedIndex = 0;
  search: String;
  currentPageAtivos: number;
  lengthAtivos = 0;
  pageSizeAtivos = 25;
  pageSizeOptionsAtivos: number[] = [25, 50, 100];
  pageEvent: PageEvent;

  currentPageInativos: number;
  lengthInativos = 0;
  pageSizeInativos = 25;
  pageSizeOptionsInativos: number[] = [25, 50, 100];
  ngOnInit()  {
    this.search = '';
    this.currentPageAtivos = 1;
    this.currentPageInativos = 1;
    this.getAtivos();  
  }

  clickPageAtivos(event:PageEvent) {
    this.currentPageAtivos = event.pageIndex + 1;
    this.pageSizeAtivos = event.pageSize;
    this.getAtivos(event);
    return event;
  }

  clickPageInativos(event:PageEvent) {
    this.currentPageInativos = event.pageIndex + 1;
    this.pageSizeInativos = event.pageSize;
    this.getAtivos(event);
    return event;
  }
  
  Search() {
    Search.setSearch(this.search);
    if(this.selectedIndex == 0) {
       this.status = 'A';
    } else if(this.selectedIndex == 1){
      this.status = 'I';
    }
    this.getAtivos();
  }
  public getAtivos(event?:PageEvent) {
    if(Search.hasSearch() === true || Search.hasStatus() === true){
      this.search  = Search.getSearch();
      this.status  = Search.getStatus();
      if (this.status == 'I') this.selectedIndex = 1;
      if (this.search == null) this.search = '';
     } else  Search.setStatus(this.status);
     this._contabilidadeService.read(this.status,this.search,this.currentPageAtivos, this.pageSizeAtivos)
       .subscribe((res: any) => {
     this.objAtivos = res.entidade; 
     this.objInativos = res.entidade; 
     this.lengthAtivos = res.totalActive;   
    });
    return event;  
  }

  Add(): void {
    this.router.navigate(['/contabilidade/edit/0']);
  }
  desativarObj(id){
    let msg = "Deseja desativar escritório?";
    let botao = "Desativar";
    this._dialogService
      .openConfirmDialog(msg, botao)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.getSubscription = this._contabilidadeService.desativarContabil(id)
            .subscribe((res: any) => {
              this.snackBar.open(res.message, undefined, {
                duration: this.autohide,
                panelClass: ['ok-snackbar']
              });
            });
        }
        this.getAtivos();
      });
  }
  ativarObj(id){
    this.getSubscription = this._contabilidadeService.reativarContabil(id)
    .subscribe((res: any) => {
      this.snackBar.open(res.message, undefined, {
        duration: this.autohide,
        panelClass: ['ok-snackbar']
      });
      this.selectedIndex = 1;
      this.status = 'I';
      this.getAtivos();
    });
  }
  tabClick(tab) {
    let index = tab.index;
    if (index == 0) {
      this.status = "A";
      Search.setStatus("A");
    } else if (index == 1) {
      this.status = "I";
      Search.setStatus("I");

    } 
    this.getAtivos();
  }
  
}
