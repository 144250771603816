import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';
import { Dashboard } from '../../models/dashboard.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
}) 
export class HomeComponent implements OnInit {
  dashboard : Dashboard;

  constructor(private dashboardService: DashboardService,
    private router: Router,) { }

  ngOnInit(): void {
    this.dashboardService.read()
      .subscribe((res: any) => {
        this.dashboard = res;
    });

  }

}
