import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Security } from 'src/app/services/login.service';
import { Search } from 'src/app/services/search.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  logout() {
    Security.clear();
    this.router.navigate(['/login']);
  }

  limparPesquisa(){
    Search.clear();
  }
}
