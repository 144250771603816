import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ClienteService } from '../../../services/cliente.service';
import { Cliente } from '../../../models/cliente.model';
import { Router } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { DialogService } from 'src/app/services/dialog.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { Search } from 'src/app/services/search.service';
import { DatePipe } from '@angular/common'; //import do datePipe


@Component({
  selector: 'app-list-cliente',
  templateUrl: './list-cliente.component.html',
  styleUrls: ['./list-cliente.component.scss'],
  providers: [DatePipe] //DatePipe como provider
})

export class ClienteListComponent implements OnInit, OnDestroy {
  public autohide = 3000;
  getSubscription: Subscription;
  clientesAtivos: Cliente[];
  clientesInativos: Cliente[];
  clientesTeste: Cliente[];
  displayedColumnsAtivos = ['id', 'nome', 'razao', 'cnpj', 'action'];
  displayedColumnsInativos = ['id', 'nome', 'razao', 'cnpj', 'action'];
  displayedColumnsTeste = ['id', 'nome', 'cnpj', 'dtValidaChave', 'action'];

  constructor(private clienteService: ClienteService, private router: Router, private datePipe: DatePipe,
    private _dialogService: DialogService,
    public snackBar: MatSnackBar) {
    this.search = '';
    
  }

  selectedIndex = 0;
  search: String;
  status: string = "A";
  currentPageAtivos: number;
  lengthAtivos = 0;
  pageSizeAtivos = 25;
  pageSizeOptionsAtivos: number[] = [25, 50, 100];
  pageEvent: PageEvent;


  currentPageInativos: number;
  lengthInativos = 0;
  pageSizeInativos = 25;
  pageSizeOptionsInativos: number[] = [25, 50, 100];

  currentPageTeste: number;
  lengthTestes = 0;
  pageSizeTeste = 25;
  pageSizeOptionsTeste: number[] = [25, 50, 100];
  //pageEventInativos: PageEvent;

  ngOnInit() {
    this.currentPageAtivos = 1;
    this.currentPageInativos = 1;
    this.currentPageTeste = 1;
    this.getAtivos();
    // this.datePipe.transform(dtValidaChave, 'dd/MM/yyyy')
  }

  ngOnDestroy(): void {

  }

  clickPageAtivos(event: PageEvent) {
    this.currentPageAtivos = event.pageIndex;
    this.pageSizeAtivos = event.pageSize;
    this.getAtivos(event);
    return event;
  }

  clickPageInativos(event: PageEvent) {
    this.currentPageInativos = event.pageIndex;
    this.pageSizeInativos = event.pageSize;
    this.getAtivos(event);
    return event;
  }

  clickPageTeste(event: PageEvent) {
    this.currentPageTeste = event.pageIndex;
    this.pageSizeTeste = event.pageSize;
    this.getTeste(event);
    return event;
  }
  Search() {
    Search.setSearch(this.search);
    if (this.selectedIndex == 0) {
      this.getAtivos(null);
    } else if(this.selectedIndex == 1) {
      this.getInativos();
    } else if(this.selectedIndex == 2) {
      this.getTeste();
    }
  }
  public getAtivos(event?: PageEvent) {
    if (Search.hasSearch() === true && Search.hasStatus() === true) {
      this.search = Search.getSearch();
      this.status = Search.getStatus();
      if (this.status == 'I') this.selectedIndex = 1;
    } else 
    Search.setStatus("A");
    this.clienteService.read(this.status, this.search, this.currentPageAtivos, this.pageSizeAtivos)
      .subscribe((res: any) => {
        this.clientesAtivos = res.entidade;
        this.clientesInativos = res.entidade;
        this.lengthAtivos = res.totalActive;
      });
    return event;
  }
  tabClick(tab) {
    let index = tab.index;
    if (index == 0) {
      this.status = "A";
      Search.setStatus("A");
    this.getAtivos();
  } else if (index == 1) {
      this.status = "I";
      Search.setStatus("I");
    this.getInativos();
  } else if (index == 2){
      this.status = "A";
      Search.setStatus("A");
    this.getTeste();
  }
    //this.getAtivos();
  }

  getInativos(event?: PageEvent) {
    this.selectedIndex = 1;
    this.clienteService.read('I', this.search, this.currentPageInativos, this.pageSizeInativos)
      .subscribe((res: any) => {
        this.clientesInativos = res.entidade;
        this.lengthInativos = res.totalInactive;
      });
    return event;
  }

  getTeste(event?: PageEvent) {
    this.selectedIndex = 2;
    this.clienteService.readTeste('A', this.search, this.currentPageTeste, this.pageSizeTeste)
      .subscribe((res: any) => {
        this.clientesTeste = res.entidade
        this.lengthInativos = res.totalInactive;
      });
    return event;
  }

  Add(): void {
    this.router.navigate(['/clientes/edit/0']);
  }

  desativarObj(id) {
    let msg = "Deseja desativar cliente?";
    let botao = "desativar";
    this._dialogService
      .openConfirmDialog(msg, botao)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.getSubscription = this.clienteService.desativarCliente(id)
            .subscribe((res: any) => {
              this.snackBar.open(res.message, undefined, {
                duration: this.autohide,
                panelClass: ['ok-snackbar']
              });

            });
        }
        this.getAtivos();
      });
  }

  ativarObj(id) {
    this.getSubscription = this.clienteService.reativarCliente(id)
      .subscribe((res: any) => {
        this.snackBar.open(res.message, undefined, {
          duration: this.autohide,
          panelClass: ['ok-snackbar']
        });
        this.getInativos();
      });
  }

  ativarClienteTeste(id) {
    this.getSubscription = this.clienteService.ativarClienteTeste(id)
      .subscribe((res: any) => {
        this.snackBar.open(res.message, undefined, {
          duration: this.autohide,
          panelClass: ['ok-snackbar']
        });
        this.getInativos();
      });
  }
}
