import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { ContabilidadeService } from 'src/app/services/contabilidade.service';
import { DialogService } from 'src/app/services/dialog.service';
import { GrupoEmpresaService } from 'src/app/services/grupoempresa.service';
import { PermissoesService } from 'src/app/services/permissoes.service';
import { ModalClientesTesteComponent } from 'src/app/components/template/modal/modal-clientes-teste/modal-clientes-teste/modal-clientes-teste.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-edit-usuario-contabil',
  templateUrl: './edit-usuario-contabil.component.html',
  styleUrls: ['./edit-usuario-contabil.component.scss']
})
export class EditUsuarioContabilComponent implements OnInit, OnDestroy {

  idPri: number;

  public form: FormGroup;
  public postSubscription: Subscription;
  public getSubscription: Subscription;
  public querySubscription: Subscription;
  public putSubscription: Subscription;
  public idCliente = this.data.idCliente;
  public autohide = 4000;

  public vincClientes: boolean = false;
  public disabledButton: boolean = true;

  displayedColumnsAtivos = ['id', 'nome', 'razao', 'action', 'checkbox'];

  selectedIndex = 0;
  search: String;
  currentPageAtivos: number;
  lengthAtivos = 0;
  pageSizeAtivos = 25;
  pageSizeOptionsAtivos: number[] = [25, 50, 100];
  pageEvent: PageEvent;
  objAtivos: any[];
  objComparar: any[];
  idGrupo: number[];
  usuarios: any;
  clientes: any;
  dialogSubscription: Subscription;
  postUsuario = {
    "idCliente": 0,
    "idUsuario": 0
  }
  constructor(private _fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data,
    private _contabilidadeService: ContabilidadeService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<EditUsuarioContabilComponent>,
    private _dialogService: DialogService,
    private permissoesService: PermissoesService,
    public dialog: MatDialog,
    private _grupoEmpresaService: GrupoEmpresaService) {
    if (this.data.acao == 'add') {
      this.form = this._fb.group({
        email: [
          "",
          Validators.compose([Validators.required])
        ]
      });
    }

  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    if (this.data.acao == 'update' || this.data.acao == 'usuarioUpdate') {
      this.search = '';
      this.currentPageAtivos = 1;
      this.getAtivos();
    }
  }

  submit() {
  }

  novoVinculo() {
    this.dialogSubscription = this._dialogService
        .vinculaClienteEscritorio(this.idPri, 'E')
        .afterClosed()
        .subscribe((res:any) => this.getAtivos());
    }

  openDialog() {
    this.dialog.open(ModalClientesTesteComponent);
  }
  consultaUsuario() {
    let email = this.form.controls.email.value;
    this.getSubscription = this._contabilidadeService.readUserSearch(email)
      .subscribe((res: any) => {
        this.usuarios = res.usuario;
        if (this.usuarios.length == 0) {
          this.snackBar.open("Usuário não encontrado", undefined, {
            duration: this.autohide,
            panelClass: ['ok-snackbar']
          });
          this.disabledButton = true;

        } else {
          this.disabledButton = false;
        }
      });
  }

  inserirId(id) {
    this.postUsuario = {
      "idCliente": this.idCliente,
      "idUsuario": id
    }
  }

  criarUsuarioContabil() {
    console.log(this.postUsuario)
    let autohide = 3000;
    this.postSubscription = this._contabilidadeService.criarUsuarioContabil(this.postUsuario)
      .subscribe(
        (res: any) => {
          this.snackBar.open(res.message, undefined, {
            duration: autohide,
            panelClass: ['ok-snackbar']
          });
          this.getAtivos();
        }
      );
  }
  vincClientesUsuario = false;
  //#region GERENCIAR USUÁRIO
  public getAtivos(event?: PageEvent) {
    this.getSubscription = this._contabilidadeService.readClientsByUser(this.data.idUsuario)
      .subscribe((res: any) => {
        let objAtivos = res.usuarioEntidade;
        this.idGrupo = res.usuarioEntidade.map(x => x.id);
        this.lengthAtivos = res.totalActive;
        this.objAtivos = objAtivos.map(x => x.Entidade);
        this.objComparar = objAtivos.map(x => x.Entidade);
        this.selectedIndex = 0;

        if (this.data.acao == 'usuarioUpdate') {
          this.vincClientesUsuario = true;
          this.checked
            = objAtivos.map((x, index) => {
              console.log(x.isAtivo)
              if (x.isAtivo == "S") {
                return true;
              } else false;
            })
        } else this.vincClientesUsuario = false;

      });
    return event;
  }

  clickPageAtivos(event: PageEvent) {
    this.currentPageAtivos = event.pageIndex + 1;
    this.pageSizeAtivos = event.pageSize;
    this.getAtivos(event);
    return event;
  }


  checked: boolean[] = [false, false];
  indexIdGrupo: number[] = [0];


  getClientesEscritorio(event?: PageEvent) {

    if (this.data.acao == 'update') {
      this.getSubscription = this._grupoEmpresaService.getByClienteContabil(this.idCliente, this.search)
        .subscribe((res: any) => {
          let objeto = res.grupoEmpresa;
          this.lengthAtivos = res.totalActive;
          this.objAtivos = objeto.map(x => x.EntidadeSecundario);
          this.vincClientes = true;
          let objComparar = this.objComparar.map(y => y.id);
          this.checked
            = this.objAtivos.map((x, index) => {
              for (let i = 0; i <= objComparar.length; i++) {
                if (x.id == objComparar[i]) {
                  this.indexIdGrupo[index] = i;
                  return true;
                } else false;
              }
            })
          this.selectedIndex = 1;
        });
      return event;
    } else {
      this.getSubscription = this._dialogService
        .vinculaClienteEscritorio(0, 'U')
        .afterClosed()
        .subscribe((id: any) => {
          this.postUsuario = {
            "idCliente": id,
            "idUsuario": this.data.idUsuario
          }
          this.criarUsuarioContabil();
        });
    }

  }

  desvincularCliente(index) {
    let msg = "Deseja desvincular cliente?";
    let botao = "desvincular";
    this._dialogService
      .openConfirmDialog(msg, botao)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.getSubscription = this._contabilidadeService.delClienteUserRelation(this.idGrupo[index])
            .subscribe((res: any) => {
              this.snackBar.open(res.message, undefined, {
                duration: this.autohide,
                panelClass: ['ok-snackbar']
              });
            });
        }
        this.getAtivos();
      });
  }

  resetarSenha() {
    this.putSubscription = this._contabilidadeService.resetPass(this.data.idUsuario)
      .subscribe((res: any) => {
        this.snackBar.open(res.message, undefined, {
          duration: this.autohide,
          panelClass: ['ok-snackbar']
        });
      });
  }

  showOptions(event, id, i) {
    if (event == true && this.data.acao == 'update') {
      this.postUsuario = {
        "idCliente": id,
        "idUsuario": this.data.idUsuario
      }
      this.criarUsuarioContabil();
    } else
      if (event == true && this.data.acao == 'usuarioUpdate') {

        this.getSubscription = this._contabilidadeService.reativarClienteUserRelation(this.idGrupo[i])
          .subscribe((res: any) => {
            this.snackBar.open(res.message, undefined, {
              duration: this.autohide,
              panelClass: ['ok-snackbar']
            });
          });

      }
      else {
        this.desvincularCliente(i);
      }
  }

  //#endregion

  voltar() {
    this.dialogRef.close(false);
  }
}
