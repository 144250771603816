import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '../models/usuario.model';
import { DataService } from './data.service';

@Injectable({
    providedIn: 'root'
  })

 
  export class LoginService {
    constructor(
    private service: DataService,
        private http: HttpClient) {
    }
    httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json'})
    }
    create(data) {
        return this.http.post(this.service.baseUrl+'/login', data, this.httpOptions);
      }

  }
    
  
export class Security {
    public static set(user: User, token: string) {
      const data = JSON.stringify(user);
  
      localStorage.setItem("kironADMUser", btoa(data));
      localStorage.setItem("kironADMToken", token);
    }
  
    public static setSession(user: User, token: string) {
      const data = JSON.stringify(user);
      sessionStorage.setItem("kironADMUser", btoa(data));
      sessionStorage.setItem("kironADMToken", token);
    }
  
    public static setUser(user: User) {
      const data = JSON.stringify(user);
      localStorage.setItem("kironADMUser", btoa(data));
    }
  
    public static setToken(token: string) {
      localStorage.setItem("kironADMToken", token);
    }
  
    public static getUser(): User {
      const data = localStorage.getItem("kironADMUser");
      if (data) {
        return JSON.parse(atob(data));
      } else {
        return null;
      }
    }
  
    public static getToken(): string {
      const data = localStorage.getItem("kironADMToken");
      const sessionData = sessionStorage.getItem("kironADMToken");
  
      if (data) {
        return data;
      } else if (sessionData) {
        return sessionData;
      } else {
        return null;
      }
    }
  
    public static hasToken(): boolean { 
      if (this.getToken()) return true;
      else return false;
    }
  
    public static clear() {
      localStorage.removeItem("kironADMUser");
      localStorage.removeItem("kironADMToken");
      sessionStorage.removeItem("kironADMUser");
      sessionStorage.removeItem("kironADMToken");
    }
  }
  