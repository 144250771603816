<br><br><br>

<mat-card>
  <mat-card-title>{{cliente.nome}}</mat-card-title>
<mat-tab-group (selectedTabChange)="tabClick($event)" animationDuration="0ms">

  <!-- ABA Configurações  -->

  <mat-tab>
    <ng-template mat-tab-label>
      <span>Configurações</span>
    </ng-template>
    <mat-card>
      <div class="row">
        <div class="col-9">
          <mat-card-subtitle>Controle de Acesso</mat-card-subtitle>
        </div>
        <div class="col-3" style="align-items: flex-end;">
          <button mat-raised-button (click)="abrirGrupoEmpresa()" class="botaoPrimario">
            Grupo de Empresas
          </button></div>
      </div>

      <mat-card-content>
        <mat-form-field appearance="outline">
          <mat-label>Chave de Acesso</mat-label>
          <input matInput placeholder="Chave de acesso" [(ngModel)]="cliente.chaveAcesso" name='chaveAcesso' readonly>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Data Validade</mat-label>
          <input matInput placeholder="Data Validade" [ngModel]="cliente.dtValidaChave | date:'short':'UTC'" readonly>
        </mat-form-field>
        
        <button style="margin-right:10px; background-color: rgb(2, 117, 216); color: rgb(255, 255, 255);" mat-raised-button (click)="gerarChave()">
          Gerar Chave
        </button>
      </mat-card-content>
    </mat-card>
    <br>

    <mat-card>
      <mat-card-subtitle>Banco de Dados</mat-card-subtitle>
      <mat-card-content>


        <mat-form-field appearance="outline">
          <mat-label>Atualizar Banco de Dados?</mat-label>
          <mat-select name="novaAtuaBD" [(ngModel)]="cliente.novaAtuaBD">
            <mat-option value="S">
              Sim
            </mat-option>
            <mat-option value="N">
              Não
            </mat-option>
          </mat-select>
        </mat-form-field>


        <mat-form-field appearance="outline">
          <mat-label>Script NF-e</mat-label>
          <input matInput placeholder="Script NF-e" [(ngModel)]="cliente.idScriptNFe">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Script Controle</mat-label>
          <input matInput placeholder="Script Controle" [(ngModel)]="cliente.idScriptCont">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Último NSU</mat-label>
          <input matInput placeholder="Último NSU" [(ngModel)]="cliente.ultNSU" name='ultNSU' >
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Último NSU CT-e</mat-label>
          <input matInput placeholder="Último NSU CT-e" [(ngModel)]="cliente.ultNSUCTe" name='ultNSUCTe' >
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Data da última consulta</mat-label>
          <input matInput placeholder="Data da última consulta"  [ngModel]="cliente.dtUltCons | date:'short':'UTC'" name='dtUltCons' readonly>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Já foi migrado?</mat-label>
          <mat-select [(ngModel)]="cliente.isMigrado" name="isMigrado">
            <mat-option value="S">
              Sim
            </mat-option>
            <mat-option value="N">
              Não
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Emite NFS-e?</mat-label>
          <mat-select [(ngModel)]="cliente.emiteNFSe" name="emiteNFSe">
            <mat-option value="S">
              Sim
            </mat-option>
            <mat-option value="N">
              Não
            </mat-option>
          </mat-select>
      </mat-form-field>
      </mat-card-content>
    </mat-card>
    <br>

    <mat-card class='card-financeiro'>
      <mat-card-subtitle>Planos / Financeiro</mat-card-subtitle>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Plano</mat-label>
          <mat-select [(ngModel)]="cliente.pctAcesso" name="plano">
            <mat-option *ngFor="let plano of planos" [value]="plano.id">
              {{plano.nome}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Data Contrato</mat-label>
          <input type="date" matInput placeholder="Data Contrato" [ngModel]="cliente.dtContrato | date:'yyyy-MM-dd'" (ngModelChange)="cliente.dtContrato" name="dtContrato" id="dtContrato">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Cliente responsável financeiro?</mat-label>
          <mat-select name="isRespFinanceiro" [(ngModel)]="isRespFinanceiro">
            <mat-option value="S">
              Sim
            </mat-option>
            <mat-option value="N">
              Não
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Valor Mensal (R$)</mat-label>
          <input matInput placeholder="Valor Mensal (R$)" [(ngModel)]="cliente.vMensal" name=" vMensal" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Valor Excedente (R$)</mat-label>
          <input matInput placeholder="Valor Excedente (R$)" [(ngModel)]="cliente.vAdicional" name=" vAdicional" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Limite de XML</mat-label>
          <input matInput placeholder="Limite de XML" [(ngModel)]="cliente.limiteNFe" name="limiteNFe">
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Dia do vencimento</mat-label>
          <input matInput placeholder="Dia do vencimento" [(ngModel)]="cliente.diaVencto" name=" diaVencto">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Dia para pagamento</mat-label>
          <input matInput placeholder="Dia para pagamento" [(ngModel)]="cliente.diaVencto" name=" diaVencto">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Forma de pagamento</mat-label>
          <mat-select [(ngModel)]="cliente.idFormaPagto" name="formapagto">
            <mat-option *ngFor="let pagto of pagtos" [value]="pagto.id">
              {{pagto.nome}}
            </mat-option>
          </mat-select>
        </mat-form-field><br>
        <mat-form-field style="width: 66%;" appearance="outline">
          <mat-label>Email para envio do boleto</mat-label>
          <input matInput placeholder="Email para envio do boleto" [(ngModel)]="cliente.emailBoleto" name="emailBoleto">
        </mat-form-field>

        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Pacote de Acesso
              </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-form-field class="inputLarge">
              <mat-chip-list #chipList>
                <mat-chip *ngFor="let produto of lstPro" [selectable]="selectable" [removable]="removable"
                  (removed)="remove(produto)">
                  {{produto.nome}}
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input placeholder="Incluir plano de acesso..." #Input [formControl]="pctAcessoCtrl"
                  [matAutocomplete]="auto" [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)">
              </mat-chip-list>
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                <mat-option *ngFor="let produto of lstPctAcesso" [value]="produto">
                  {{produto.nome}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </mat-card>
    <br>

    <mat-card class='card-financeiro'>
      <mat-card-title>Histórico de Reajuste</mat-card-title>
      <div>
        <table mat-table [dataSource]="objAtivos">
          <ng-container matColumnDef="data">
            <th mat-header-cell *matHeaderCellDef>Data</th>
            <td mat-cell *matCellDef="let row">{{row.data}}</td>
          </ng-container>

          <ng-container matColumnDef="vAntigo">
            <th mat-header-cell *matHeaderCellDef>Valor Antigo</th>
            <td mat-cell *matCellDef="let row">{{row.vAntigo}}</td>
          </ng-container>


          <ng-container matColumnDef="valor">
            <th mat-header-cell *matHeaderCellDef>Valor Reajustado</th>
            <td mat-cell *matCellDef="let row">{{row.vReajustado}}</td>
          </ng-container>

          <ng-container matColumnDef="indice">
            <th mat-header-cell *matHeaderCellDef>Índice %</th>
            <td mat-cell *matCellDef="let row">{{row.indice}}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsAtivos"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsAtivos;"></tr>
        </table>
      </div>
    </mat-card>

  </mat-tab>

  <!-- ABA Notas de serviço  -->

  <mat-tab>

    <ng-template mat-tab-label>
      <span>NF de serviço</span>
    </ng-template>
    <div class="row" style="padding:15px;" [formGroup]="dateGroup">
      <mat-form-field style="display: flex;flex-direction: row;" class="inputMedium">
        <label>
          Selecionar o período
        </label>
        <mat-select [(ngModel)]="defaultValue" (selectionChange)="onChangeSelect($event)" formControlName="dataSelect">
          <mat-option value="Todos">
            Todos
          </mat-option>
          <mat-option value="Custom">
            Customizado
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div style="padding:15px;" *ngIf="enabledDatePicker">
        <mat-form-field class="inputSmall date">
          <mat-label>Data Inicial</mat-label>
          <input matInput (dateChange)="onChangeFirstDatePicker($event)" [matDatepicker]="picker1"
            formControlName="dateIni">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="inputSmall date">
          <mat-label>Data Final</mat-label>
          <input matInput (dateChange)="onChangeSecDatePicker($event)" [matDatepicker]="picker2"
            formControlName="dateFin">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>

      </div>
      <button class="botaoPrimario" style="margin:15px;" mat-raised-button (click)="filtrarValores()">
        Filtrar
      </button>
    </div>

      <div *ngIf="objNotServ != undefined" style="padding:15px;">
        <div class="row resumo">
          <div class="col-3" ><div class="row"><span>Total:&nbsp;&nbsp; </span> {{objNotServ[0].total  | currency : 'BRL'}}</div></div>
          <div class="col-9"><div class="row"><span>Média:&nbsp;&nbsp; </span> {{objNotServ[0].media  | currency : 'BRL'}}</div></div>
        </div>
      </div>

    <div>
      <table mat-table [dataSource]="nfservicos">
        <!-- Id Column -->

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>ID</th>
          <td mat-cell *matCellDef="let row">{{row.id}}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="dtEmissao">
          <th mat-header-cell *matHeaderCellDef>Emissão</th>

          <td mat-cell matTooltip="{{row.dtEmissao | date:'medium':'UTC'}}" *matCellDef="let row">
            {{row.dtEmissao | date:'fullDate':'UTC'}}</td>
        </ng-container>

        <ng-container matColumnDef="emailBoleto">
          <th mat-header-cell *matHeaderCellDef [style.display]="'none'">e-mail</th>
          <td mat-cell *matCellDef="let row" [style.display]="'none'">{{row.Entidade.emailBoleto}}</td>
        </ng-container>

        <ng-container matColumnDef="numeroNota">
          <th mat-header-cell *matHeaderCellDef>Nro Nota</th>
          <td mat-cell *matCellDef="let row">{{row.numeroNota}}</td>
        </ng-container>

        <ng-container matColumnDef="vServico">
          <th mat-header-cell *matHeaderCellDef>Valor</th>
          <td mat-cell *matCellDef="let row">{{row.vServico  | currency : 'BRL'}}</td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let row">{{row.Status.nome}}</td>
        </ng-container>

        <ng-container matColumnDef="dtEmail">
          <th mat-header-cell *matHeaderCellDef [style.display]="'none'">Enviado</th>
          <td mat-cell *matCellDef="let row" [style.display]="'none'">{{row.dtEmail | date:'short':'UTC'}}</td>
        </ng-container>

        <ng-container matColumnDef="razao">
          <th mat-header-cell *matHeaderCellDef [style.display]="'none'">Razão Social</th>
          <td mat-cell *matCellDef="let row" [style.display]="'none'">{{row.Entidade.razao}}</td>
        </ng-container>
        <ng-container matColumnDef="resumo">
          <th mat-header-cell *matHeaderCellDef>Resumo</th>
          <td mat-cell *matCellDef="let row">{{row.Entidade.resumo}}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Ações</th>
          <td mat-cell *matCellDef="let row">
            <div>
              <button
                matTooltip="Enviado em {{row.dtEmail | date:'fullDate':'UTC'}} - {{row.dtEmail | date:'shortTime'}}, para:  {{row.Entidade.emailBoleto}}"
                mat-icon-button color="primary" (click)="enviarEmail(row.id)" class="email">
                <mat-icon aria-label="e-mail">email</mat-icon>
              </button>

              <button mat-icon-button color="primary" (click)="print(row.id)" class="print">
                <mat-icon aria-label="Imprimir">print</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </mat-tab>

  <!-- ABA Financeiro  -->

  <mat-tab>
    <ng-template mat-tab-label>
      <span>Financeiro</span>
    </ng-template>

    <div class="row" style="padding:15px;" [formGroup]="FinDateGroup">
      <mat-form-field style="display: flex;flex-direction: row;" class="inputMedium">
        <label>
          Selecionar o período
        </label>
        <mat-select [(ngModel)]="defaultValue" (selectionChange)="onChangeSelect($event)" formControlName="dataSelect">
          <mat-option value="Todos">
            Todos
          </mat-option>
          <mat-option value="Custom">
            Customizado
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div style="padding:15px;" *ngIf="enabledDatePicker">
        <mat-form-field class="inputSmall date">
          <mat-label>Data Inicial</mat-label>
          <input matInput (dateChange)="onChangeFirstDatePicker($event)" [matDatepicker]="picker1"
            formControlName="dateIni">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="inputSmall date">
          <mat-label>Data Final</mat-label>
          <input matInput (dateChange)="onChangeSecDatePicker($event)" [matDatepicker]="picker2"
            formControlName="dateFin">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>

      </div>
      <button class="botaoPrimario" *ngIf="!enabledDatePicker" style="margin:15px;" mat-raised-button (click)="getFinanceiro()">
        Filtrar
      </button>
      <button class="botaoPrimario" *ngIf="enabledDatePicker" style="margin:15px;" mat-raised-button (click)="filtrarValoresVINDI()">
        Filtrar
      </button>
    </div>

    <div *ngIf="objNotVindi != undefined" style="padding:15px;">
      <div class="row resumo">
        <div class="col-6" ><div class="row"><span>Total:&nbsp;&nbsp; </span> {{objNotVindi[0].total  | currency : 'BRL'}}</div></div>
        <div class="col-6"><div class="row"><span>Média:&nbsp;&nbsp; </span> {{objNotVindi[0].media  | currency : 'BRL'}}</div></div>
      </div>
    </div>

    <div >

      <table mat-table [dataSource]="charges">

        <ng-container matColumnDef="created_at">
          <th mat-header-cell *matHeaderCellDef>Emissão</th>
          <td mat-cell *matCellDef="let row">{{row.created_at | date:'short'}}</td>
        </ng-container>

        <ng-container matColumnDef="due_at">
          <th mat-header-cell *matHeaderCellDef>Vencimento</th>
          <td mat-cell *matCellDef="let row">{{row.due_at | date:'shortDate'}}</td>
        </ng-container>

        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef>Valor</th>
          <td mat-cell *matCellDef="let row">{{row.amount  | currency : 'BRL'}}</td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>status</th>
          <td mat-cell *matCellDef="let row">
            <p class="status" [ngClass]="{
            'status-1':row.status == 'pago',
            'status-2':row.status == 'cancelado',
            'status-3':row.status == 'pendente'}">
              {{row.status}}
            </p>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsCharges"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsCharges;"></tr>
      </table>
    </div>
    <br><br>
  </mat-tab>

  <!-- ABA Usuários  -->

  <mat-tab>
    <ng-template mat-tab-label>
      <span>Usuários</span>
    </ng-template>

    <!-- Usuários -->
    <mat-card>
      <mat-card-title class="cardUsuarios">Usuários</mat-card-title>
      <div class="row" style="justify-content: flex-end;padding: 10px;">
        <button class="botaoPrimario" mat-raised-button (click)="vincularUsuario('add', 0)">
         Vincular Usuário
        </button>
      </div>
      <mat-card-content>
        <div>
          <table mat-table [dataSource]="usuarios">
            <!-- Id Column -->

            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef>ID</th>
              <td mat-cell *matCellDef="let row">{{row.id}}</td>
            </ng-container>

            <ng-container matColumnDef="nome">
              <th mat-header-cell *matHeaderCellDef>Nome</th>
              <td mat-cell *matCellDef="let row" >
                <span *ngIf="row.Usuario.nome != null" >{{row.Usuario.nome}}</span></td>
            </ng-container>
            <ng-container matColumnDef="login">
              <th mat-header-cell *matHeaderCellDef>Login (e-mail)</th>
              <td mat-cell *matCellDef="let row">{{row.Usuario.login}}</td>
            </ng-container>

            <ng-container matColumnDef="dtInclusao">
              <th mat-header-cell *matHeaderCellDef>Criado em</th>
              <td mat-cell *matCellDef="let row">{{row.Usuario.dtInclusao | date:'short':'UTC'}}</td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>Desativado</th>
              <td mat-cell *matCellDef="let row">{{row.Usuario.dtInativo}}</td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>Ações</th>
              <td mat-cell *matCellDef="let row;  let i = index">
                <div>
                  <button matTooltip="Resetar senha" mat-icon-button color="primary" (click)="resetarSenha(row.id)"
                    class="reset">
                    <mat-icon aria-label="Resetar"> layers_clear</mat-icon>
                  </button>
                  <!-- <button matTooltip="Desvincular Usuário" mat-icon-button color="primary" (click)="desvincular(i)"
                  class="reset">
                    <mat-icon aria-label="Desvincular">delete_outline</mat-icon>
                  </button> -->
                  <mat-slide-toggle
                    [(ngModel)]="this.arrChecked[i]"
                    class="example-margin"
                    [color]="color"
                    (change)="changed($event,i )">
                     <span *ngIf="this.arrChecked[i] == true ">Ativo</span>
                     <span *ngIf="this.arrChecked[i] == false">Inativo</span>
                  </mat-slide-toggle>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsUsuarios"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsUsuarios;"></tr>
          </table>
        </div>
      </mat-card-content>
    </mat-card>

  </mat-tab>

  <!-- ABA Gerais  -->

  <mat-tab>
    <ng-template mat-tab-label>
      <span>Gerais</span>
    </ng-template>
    <div class="row">
      <div class="col-9">
        <h2 style="padding: 15px;">Informações Escritório vinculado</h2>
      </div>
      <div class="col-3" style="align-items: flex-end; padding: 15px;">
        <button class="botaoPrimario" mat-raised-button [disabled]="temEscritorio" (click)="vincularEscritorio()">
          Vincular Escritório
        </button>
      </div>
    </div>
    <div *ngIf="!temEscritorio">
      <span class="not-found">
        Nenhum escritório vinculado.
      </span>
    </div>
    <div *ngIf="temEscritorio" class="row">
      <table class=" table-custom" style="padding:15px">
        <tr>
          <th>Código</th>
          <th>Nome</th>
          <th>Razão</th>
          <th></th>
        </tr>
        <tr>
          <td><p>{{entidadeEsc.Escritorio.id}}</p></td>
          <td><p>{{entidadeEsc.Escritorio.nome}}</p></td>
          <td><p>{{entidadeEsc.Escritorio.razao}}</p></td>
          <td>
            <button matTooltip="Desvincular Escritório" mat-icon-button color="primary" (click)="desvincularEscritorio()"
            class="reset">
              <mat-icon aria-label="Desvincular">delete_outline</mat-icon>
            </button>
        </td>
        </tr>
      </table>
      <ng-container class="col-3" style="align-items: flex-end; padding: 15px;">
       
      </ng-container>
    </div>

    <!-- Certificado Digital -->
    <div class="row">
      <h2 style="padding: 15px;">Certificado digital</h2>
    </div>
    <table class="table-custom">
      <tr>
        <th>CNPJ</th>
        <th>Data de Vencimento</th>
        <th>Razão social</th>
        <th>Série</th>
        <th>Tipo</th>
      </tr>
      <tr>
        <td><p>{{cliente.certCNPJ}}</p></td>
        <td><p>{{cliente.certDtVencto}}</p></td>
        <td><p>{{cliente.certRazao}}</p></td>
        <td><p>{{cliente.certSerie}}</p></td>
        <td><p>{{cliente.certTipo}}</p></td>
      </tr>
    </table>
    <div class="row">
      <h2 style="padding: 15px;">Observações Adicionais</h2>
    </div>
    <div class="row">
      <mat-form-field class="example-full-width" style="width: 100%;" appearance="fill">
        <mat-label>Observações</mat-label>
        <textarea matInput [(ngModel)]="cliente.obs" name="obs"></textarea>
      </mat-form-field>
    </div>
  </mat-tab>

</mat-tab-group>
<div style="margin-top:20px;">
  <button class="botaoPrimario" mat-raised-button (click)="save()" color="primary">
    Salvar
  </button>
  <button class="botaoSecundario" style="margin-right:10px;" mat-raised-button (click)="cancel()">
    Cancelar
  </button>

</div>
<br><br>
</mat-card>