<h1 mat-dialog-title>Selecione o período</h1>
<div mat-dialog-content>
      <mat-form-field appearance="outline" class="col-11">
        <mat-label>Selecione o Mês</mat-label>
        <mat-select>
          <mat-option *ngFor="let mes of meses" [value]="mes.value">
            {{mes.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    <mat-form-field appearance="outline" class="col-11">
      <mat-label>Digite o Ano</mat-label>
      <!-- <input type="number" matInput placeholder="Ano" [(ngModel)]="year" [maxLength]="yearTeste"> -->
      <input type="number" matInput placeholder="Ano" [(ngModel)]="year" min="{{minYear}}" max="{{maxYear}}">
    </mat-form-field>
</div>
<div mat-dialog-actions>
  <button class="botaoSecundario" mat-button (click)="cancel()">Cancelar</button>
  <button class="botaoPrimario" mat-button cdkFocusInitial>Salvar</button>
</div>