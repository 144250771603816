import { BrowserModule } from '@angular/platform-browser';
import { NgModule,  LOCALE_ID  } from '@angular/core';
import { AuthService } from './services/auth.service';
import { ReactiveFormsModule} from '@angular/forms'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './components/template/header/header.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FooterComponent } from './components/template/footer/footer.component';
import { NavComponent } from './components/template/nav/nav.component';
import { MatSidenavModule} from '@angular/material/sidenav';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { MatListModule} from '@angular/material/list';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatCardModule} from '@angular/material/card';
import { MatButtonModule} from '@angular/material/button';
import {MatChipsModule} from '@angular/material/chips';
import { MatSnackBarModule} from '@angular/material/snack-bar';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import {MatTabsModule} from '@angular/material/tabs';
import {MatIconModule} from '@angular/material/icon';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatMenuModule} from '@angular/material/menu';
import { HomeComponent } from './views/home/home.component';
import { ClienteEditComponent } from './views/cliente/edit/edit-cliente.component';
import { ClienteListComponent } from './views/cliente/list/list-cliente.component';
import { ClienteInfoComponent } from './views/cliente/info/info-cliente.component';
import { NfservicoListComponent } from './views/nfservico/list/list-nfservico.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDialogModule} from '@angular/material/dialog';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatNativeDateModule} from '@angular//material/core';
import { registerLocaleData } from  '@angular/common';
import ptBr from '@angular/common/locales/pt';
import { ListContabilidadeComponent } from './views/contabilidade/list/list-contabilidade.component';
import { ProcuraCpfCnpjComponent } from './components/template/modal/procura-cpf-cnpj/procura-cpf-cnpj.component';
import { EditContabilidadeComponent } from './views/contabilidade/edit/edit-contabilidade.component';
import { ActionTabContabilidadeComponent } from './views/contabilidade/action/action-tab-contabilidade/action-tab-contabilidade.component';
import { ListContabilClientesComponent } from './views/contabilidade/action/list-contabil-clientes/list-contabil-clientes.component';
import { VinculaClienteContabilComponent } from './components/template/modal/vincula-cliente-contabil/vincula-cliente-contabil.component';
import { ConfirmDialogComponent } from './components/template/modal/confirm-dialog/confirm-dialog.component';
import { ListUsuarioContabilComponent } from './views/contabilidade/action/list-usuario-contabil/list-usuario-contabil.component';
import { EditUsuarioContabilComponent } from './views/contabilidade/action/edit-usuario-contabil/edit-usuario-contabil.component';
import { ListGrupoEmpresaComponent } from './views/cliente/edit/list-grupo-empresa/list-grupo-empresa.component';
import { ModalChaveAcessoComponent } from './views/cliente/info/modal-chave-acesso/modal-chave-acesso.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { UsuariosComponent } from './views/usuarios/usuarios.component';
import { LoginComponent } from './views/login/login.component';
import { FramePageComponent } from './views/master/frame-page/frame-page.component';
import { RouterModule } from '@angular/router';
//  import { NgxMaskModule } from 'ngx-mask';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { FechamentoComponent } from './views/fechamento/fechamento.component';
import { NgSelect2Module } from 'ng-select2';
import { ModalClientesTesteComponent } from './components/template/modal/modal-clientes-teste/modal-clientes-teste/modal-clientes-teste.component';
import { ModalCriarFechamentoComponent } from './views/fechamento/modal-criar-fechamento/modal-criar-fechamento.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';




registerLocaleData(ptBr);


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    NavComponent,
    HomeComponent,
    ClienteEditComponent,
    ClienteListComponent,
    NfservicoListComponent,
    ClienteInfoComponent,
    ListContabilidadeComponent,
    ProcuraCpfCnpjComponent,
    EditContabilidadeComponent,
    ActionTabContabilidadeComponent,
    ListContabilClientesComponent,
    VinculaClienteContabilComponent,
    ConfirmDialogComponent,
    ListUsuarioContabilComponent,
    EditUsuarioContabilComponent,
    ListGrupoEmpresaComponent,
    ModalChaveAcessoComponent,
    UsuariosComponent,
    LoginComponent,
    FechamentoComponent,
    FramePageComponent,
    ModalClientesTesteComponent,
    ModalCriarFechamentoComponent
    
  ],
  imports: [
    MatCheckboxModule,
    MatDatepickerModule,
    MatButtonModule,
    MatFormFieldModule,
    MatNativeDateModule, 
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatCardModule,
    MatChipsModule,
    MatButtonModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatExpansionModule,
    HttpClientModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatTabsModule,
    MatIconModule,
    MatGridListModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatDialogModule,
    MatMenuModule,
    RouterModule,
    CurrencyMaskModule,
    NgSelect2Module,
    MatProgressSpinnerModule,

    // NgxMaskModule.forRoot({
    //   dropSpecialCharacters: false
    // }),

  ],
  providers: [
    AuthService,
    {
    provide: LOCALE_ID,
    useValue: 'pt',
    },
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent],
  entryComponents:[
    ProcuraCpfCnpjComponent, 
    VinculaClienteContabilComponent,
    ConfirmDialogComponent,
    EditUsuarioContabilComponent,
    ListGrupoEmpresaComponent,
    ModalChaveAcessoComponent 

  ]
})
export class AppModule { }
