<br><br><br>
<mat-card>
<div fxLayout="row">
  <div class="teste">
    <i class="material-icons">
      ballot
    </i>
    <span>
      NF de Serviço
    </span>
</div><br>

  <div class="row">
    <div *ngIf="habilitaFiltro == true" class="row"  [formGroup]="dateGroup">
      <mat-form-field style="display: flex;flex-direction: row;" appearance="outline">
        <mat-label>Selecionar o período</mat-label>
        <mat-select [(ngModel)]="defaultValue" (selectionChange)="onChangeSelect($event)" formControlName="dataSelect">
          <mat-option value="Todos">
            Todos
          </mat-option>
          <mat-option value="Custom">
            Customizado
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div *ngIf="enabledDatePicker">
        <mat-form-field class="inputSmall date" appearance="outline">
          <mat-label>Data Inicial</mat-label>
          <input matInput (dateChange)="onChangeFirstDatePicker($event)" [matDatepicker]="picker1"
            formControlName="dateIni">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="inputSmall date" appearance="outline">
          <mat-label>Data Final</mat-label>
          <input matInput (dateChange)="onChangeSecDatePicker($event)" [matDatepicker]="picker2"
            formControlName="dateFin">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>

      </div>
      <button class="botaoPrimario" style="margin:15px;" mat-raised-button (click)="filtrarValores()">
        Filtrar
      </button>
    </div>

  </div>
  <div class="row">
    <mat-form-field class="search-field" appearance="outline">
      <mat-icon (click)="Search()" matSuffix>search</mat-icon>
      <input matInput placeholder="Pesquise aqui" class="search-input" [(ngModel)]="search" (keyup.enter)="Search()">
    </mat-form-field>
  </div>

</div>

<mat-tab-group animationDuration="0ms" [(selectedIndex)]=selectedIndex (selectedTabChange)="tabClick($event)">
  <mat-tab>
    <ng-template mat-tab-label>
      <span>NF de serviço</span>
    </ng-template>
    <div *ngIf="objNotServ != undefined" style="padding:15px;">
      <div class="row resumo">
        <div class="col-3">
          <div class="row"><span>Total:&nbsp;&nbsp; </span><span >{{objNotServ[0].total | currency : 'BRL'}}</span> </div>
        </div>
        <div class="col-9">
          <div class="row"><span>Média:&nbsp;&nbsp; </span> {{objNotServ[0].media  | currency : 'BRL'}}</div>
        </div>
      </div>
    </div>
    <ng-template mat-tab-label>
      Transmitidas
    </ng-template>
    <div class="mat-elevation-z4">
      <table mat-table [dataSource]="nfservicosTrans">
        <!-- Id Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef style="width: fit-content;">ID</th>
          <td mat-cell *matCellDef="let row">{{row.id}}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="dtEmissao">
          <th mat-header-cell *matHeaderCellDef style="width: fit-content;">Emissão</th>

          <td mat-cell matTooltip="{{row.dtEmissao | date:'medium':'UTC'}}" *matCellDef="let row">
            {{row.dtEmissao | date:'fullDate':'UTC'}}</td>
        </ng-container>

        <ng-container matColumnDef="nome">
          <th mat-header-cell *matHeaderCellDef>Cliente</th>
          <td mat-cell matTooltip="{{row.Entidade.razao}}" *matCellDef="let row">{{row.Entidade.nome}}</td>
        </ng-container>

        <ng-container matColumnDef="emailBoleto">
          <th mat-header-cell *matHeaderCellDef [style.display]="'none'">e-mail</th>
          <td mat-cell *matCellDef="let row" [style.display]="'none'">{{row.Entidade.emailBoleto}}</td>
        </ng-container>

        <ng-container matColumnDef="numeroNota">
          <th mat-header-cell *matHeaderCellDef style="width: 6%;">Nº Nota</th>
          <td mat-cell *matCellDef="let row">{{row.numeroNota}}</td>
        </ng-container>

        <ng-container matColumnDef="vServico">
          <th mat-header-cell *matHeaderCellDef style="width: 6%;">Valor</th>
          <td mat-cell *matCellDef="let row">{{row.vServico | currency : 'BRL'}}</td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let row">{{row.Status.nome}}</td>
        </ng-container>
        <ng-container matColumnDef="dtEmail">
          <th mat-header-cell *matHeaderCellDef [style.display]="'none'">Enviado</th>
          <td mat-cell *matCellDef="let row" [style.display]="'none'">{{row.dtEmail | date:'short':'UTC'}}</td>
        </ng-container>

        <ng-container matColumnDef="razao">
          <th mat-header-cell *matHeaderCellDef [style.display]="'none'">Razão Social</th>
          <td mat-cell *matCellDef="let row" [style.display]="'none'">{{row.Entidade.razao}}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Ações</th>
          <td mat-cell *matCellDef="let row; let i = index">
            <div class="row">
              <button
                matTooltip="Enviado em {{row.dtEmail | date:'fullDate':'UTC'}} - {{row.dtEmail | date:'shortTime'}}, para:  {{row.Entidade.emailBoleto}}"
                mat-icon-button color="primary" (click)="enviarEmail(row.id,i)" class="email">
                <mat-icon aria-label="e-mail">email</mat-icon>
              </button>
              <button mat-icon-button color="primary" (click)="print(row.id)" class="print">
                <mat-icon aria-label="Imprimir">print</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsTrans"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsTrans; index as i "></tr>
      </table> 
    </div>
    <mat-paginator 
        [length]="length"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        (page)="pageEvent = clickPage($event)">
    </mat-paginator>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <span (click)="getAguardando()">Aguardando</span>
    </ng-template>

    <div class="mat-elevation-z4">
      <table mat-table [dataSource]="nfservicosAguard">
        <!-- Id Column -->

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>ID</th>
          <td mat-cell *matCellDef="let row">{{row.id}}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="dtEmissao">
          <th mat-header-cell *matHeaderCellDef>Emissão</th>
          <td mat-cell *matCellDef="let row">{{row.dtEmissao | date:'medium':'UTC'}}</td>
        </ng-container>

        <ng-container matColumnDef="nome">
          <th mat-header-cell *matHeaderCellDef>Cliente</th>
          <td mat-cell *matCellDef="let row">{{row.Entidade.nome}}</td>
        </ng-container>

        <ng-container matColumnDef="emailBoleto">
          <th mat-header-cell *matHeaderCellDef [style.display]="'none'">e-mail</th>
          <td mat-cell *matCellDef="let row" [style.display]="'none'">{{row.Entidade.emailBoleto}}</td>
        </ng-container>

        <ng-container matColumnDef="numeroNota">
          <th mat-header-cell *matHeaderCellDef>Nro Nota</th>
          <td mat-cell *matCellDef="let row">{{row.numeroNota}}</td>
        </ng-container>

        <ng-container matColumnDef="vServico">
          <th mat-header-cell *matHeaderCellDef>Valor</th>
          <td mat-cell *matCellDef="let row">{{row.vServico  | currency : 'BRL'}}</td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let row">{{row.Status.nome}}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Ações</th>
          <td mat-cell *matCellDef="let row">
            <button mat-icon-button color="primary" (click)="enviarNFSe(row.id)" class="arrow_upward">
              <mat-icon aria-label="e-mail">arrow_upward</mat-icon>
            </button>
            <!-- <mat-spinner></mat-spinner> -->
            <!--
                 <button mat-icon-button color="primary" (click)="print(row._id)">
                    <mat-icon aria-label="Delete">print</mat-icon>
                  </button>
                 -->
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumnsAguard"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsAguard;"></tr>
      </table>
    </div>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <span (click)="getCanceladas()">Canceladas</span>
    </ng-template>

    <div class="mat-elevation-z4">
      <table mat-table [dataSource]="nfservicosCanc">
        <!-- Id Column -->

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>ID</th>
          <td mat-cell *matCellDef="let row">{{row.id}}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="dtEmissao">
          <th mat-header-cell *matHeaderCellDef>Emissão</th>
          <td mat-cell *matCellDef="let row">{{row.dtEmissao | date:'medium':'UTC'}}</td>
        </ng-container>

        <ng-container matColumnDef="nome">
          <th mat-header-cell *matHeaderCellDef>Cliente</th>
          <td mat-cell *matCellDef="let row">{{row.Entidade.nome}}</td>
        </ng-container>

        <ng-container matColumnDef="emailBoleto">
          <th mat-header-cell *matHeaderCellDef [style.display]="'none'">e-mail</th>
          <td mat-cell *matCellDef="let row" [style.display]="'none'">{{row.Entidade.emailBoleto}}</td>
        </ng-container>

        <ng-container matColumnDef="numeroNota">
          <th mat-header-cell *matHeaderCellDef>Nro Nota</th>
          <td mat-cell *matCellDef="let row">{{row.numeroNota}}</td>
        </ng-container>

        <ng-container matColumnDef="vServico">
          <th mat-header-cell *matHeaderCellDef>Valor</th>
          <td mat-cell *matCellDef="let row">{{row.vServico  | currency : 'BRL'}}</td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let row">{{row.Status.nome}}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Ações</th>
          <td mat-cell *matCellDef="let row; let i = index">
            <button mat-icon-button color="primary" (click)="enviarEmail(row.id,i)" class="email">
              <mat-icon aria-label="e-mail">email</mat-icon>
            </button>
            <!--
                 <button mat-icon-button color="primary" (click)="print(row._id)">
                    <mat-icon aria-label="Delete">print</mat-icon>
                  </button>
                 -->
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumnsCanc"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsCanc"></tr>
      </table>
    </div>
  </mat-tab>
</mat-tab-group>
</mat-card>

<!-- <mat-paginator 
    [length]="length"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    (page)="pageEvent = clickPage($event)">
</mat-paginator> -->