<mat-card class="card-dados">
    <h1 class="title">Pesquisar número de documento </h1>
    <form [formGroup]="form" class="search-form" (ngSubmit)="submit()">
        <mat-form-field>
          <mat-label>
            CPF/CNPJ
          </mat-label>
          <input formControlName="cnpjcpf" matInput  maxlength="17" >
          <button mat-button matSuffix mat-icon-button>
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </form>
      <button mat-raised-button class="botaoSecundario" (click)="voltar()">
        Voltar
      </button>
      <br><br>
</mat-card>
