<mat-sidenav-container class="container" style="background-color: #353f46;">
    <mat-sidenav class="sidenav" mode="side" opened 
        fixedInViewport="true" fixedTopGap="64" >
        <mat-nav-list class="nav-list">
          
            <a mat-list-item (click)="limparPesquisa()"routerLink="/">
                <i class="material-icons">
                    home
                </i>
                Início
            </a>
            <a mat-list-item (click)="limparPesquisa()" routerLink="/clientes">
                <i class="material-icons">
                    group
                </i>
                Clientes
            </a>
            <a mat-list-item (click)="limparPesquisa()" routerLink="/contabilidade">
                <i class="material-icons">
                    work
                </i>
                Escritórios Contábeis
            </a>    
            <a mat-list-item (click)="limparPesquisa()" routerLink="/nfservicos">
                <i class="material-icons">
                    ballot
                </i>
                NF de Serviço
            </a>
            <a mat-list-item (click)="limparPesquisa()" routerLink="/usuarios">
                <i class="material-icons">
                    person
                </i>
                Usuários
            </a>
            <a mat-list-item (click)="limparPesquisa()" routerLink="/fechamento">
                <i class="material-icons">
                    lock
                </i>
                <!-- <span class="material-icons-outlined">
                    lock
                    </span> -->
                Fechamento
            </a>
            <a mat-list-item (click)="limparPesquisa()" (click)="logout()" >
                <i class="material-icons">
                    exit_to_app
                </i>
                Sair
            </a>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="content">
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
