import { Injectable } from '@angular/core';
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root'
})
export class DataService {
  
  public baseUrlDataSnap = 'http://cpro28883.publiccloud.com.br:8081/datasnap/adm/TSM';
 // public baseUrl = 'http://localhost:8085';
  //public baseUrl = 'http://cpro28883.publiccloud.com.br:8083';
 // public baseUrl = 'http://cpro28883.publiccloud.com.br:8085';
  public baseUrl = 'https://kironadmapi.azurewebsites.net';
 // public baseUrl = 'http://192.168.15.70:8085';

  constructor(private snackBar: MatSnackBar) { }

  showMessage(msg: string, isError: boolean = false): void {
    this.snackBar.open(msg, "X", {
      duration: 5000,
      horizontalPosition: "right",
      verticalPosition: "top",
      panelClass: isError ? ["msg-error"] : ["msg-success"],
    });
  }
}
