import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ContabilidadeService } from 'src/app/services/contabilidade.service';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss']
})
export class UsuariosComponent implements OnInit,OnDestroy {
 
  objAtivos: any[];

  displayedColumnsAtivos = ['id', 'nome', 'login', 'action'];
  displayedColumnsInativos = ['id', 'nome', 'login', 'action'];

  getSubscription: Subscription;
  postSubscription: Subscription;
  delSubscription: Subscription;
  dialogSubscription: Subscription;
  
  selectedIndex = 0;
  search: String;
  currentPageAtivos: number;
  lengthAtivos = 0;
  pageSizeAtivos = 25;
  pageSizeOptionsAtivos: number[] = [25, 50, 100];
  pageEvent: PageEvent;

  currentPageInativos: number;
  lengthInativos = 0;
  pageSizeInativos = 25;
  pageSizeOptionsInativos: number[] = [25, 50, 100];

  constructor(private _contabilidadeService: ContabilidadeService,
    private _route: ActivatedRoute,
    private _dialogService: DialogService) { }

  ngOnInit(): void {
    this.search = '';
    this.currentPageAtivos = 1;
    this.currentPageInativos = 1;
    this.getAtivos();
  }

  ngOnDestroy(): void {
    if(this.getSubscription)this.getSubscription.unsubscribe();
    if(this.postSubscription)this.postSubscription.unsubscribe();
    if(this.delSubscription)this.delSubscription.unsubscribe();
    if(this.dialogSubscription)this.dialogSubscription.unsubscribe();

  }


  clickPageAtivos(event:PageEvent) {
    this.currentPageAtivos = event.pageIndex + 1;
    this.pageSizeAtivos = event.pageSize;
    this.getAtivos(event);
    return event;
  }

  clickPageInativos(event:PageEvent) {
    this.currentPageInativos = event.pageIndex + 1;
    this.pageSizeInativos = event.pageSize;
    this.getAtivos(event);
    return event;
  }
  
  Search() {
    if(this.selectedIndex == 0) {
       this.getAtivos(null);
    } 
  }

  public getAtivos(event?: PageEvent) {
    this.selectedIndex = 0;
    this.getSubscription = this._contabilidadeService.readAllUseSearch('',this.search,this.currentPageAtivos, this.pageSizeAtivos)
      .subscribe((res: any) => {
        let objAtivos = res.usuario;
        this.lengthAtivos = res.count;
        this.objAtivos = objAtivos;
      });
    return event;
  }

  gerenciarUsuario(acao, idUsuario){
    this.dialogSubscription = this._dialogService.gerenciarUsuario(0, acao, idUsuario)
    .afterClosed()
    .subscribe((res:any) => this.getAtivos());
  }


}
