import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ClienteService } from '../../../services/cliente.service';
import { DataService } from '../../../services/data.service';
import { Cliente } from '../../../models/cliente.model';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { EmpresasRF } from 'src/app/models/empresasRF.model';
import { EmpresasRFService } from '../../../services/empresasRF.service';
import { Subscription } from 'rxjs';
import { DialogService } from 'src/app/services/dialog.service';
import { CustomersService } from '../../../services/vindi.customers.service';
import { Customers, Address, Phones} from '../../../models/vindi.customers.model';
import {Location} from '@angular/common';

declare var $:any;

@Component({
  selector: 'app-edit-cliente',
  templateUrl: './edit-cliente.component.html',
  styleUrls: ['./edit-cliente.component.scss']
})
export class ClienteEditComponent implements OnInit {

  public dialogSubscription: Subscription;

  public iscnpj: boolean = true;
  cliente = {
    "id": 0,
    "cnpj": '',
    "nome": '',
    "razao": '',
    "endereco": '',
    "enderecoNumero": '',
    "complemento": '',
    "bairro": '',
    "cep": '',
    "cidade": '',
    "idCidade": '',
    "uf": '',
    "fone": '',
    "email": '',
    "contato": '',
    "emailBoleto": '',
    "isAtivo": "S",
    "isCliente": "S",
    "ie": '',
    "isRespFinanceiro": 0,
    "idVindi": "0",
    "isProcessed": 0,
  };
  customer = {} as Customers;
  address = {} as Address;
  phones = {} as Phones;
  isRespFinanceiro: string = "N";  


  isAtivo: boolean;
  id: number;
  empresaRF: EmpresasRF;
  constructor(private clienteService: ClienteService,
    private dataService: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private _dialogService: DialogService,
    private dialog: MatDialog,
    private empresaRFService: EmpresasRFService,
    private customersService: CustomersService,
    private location: Location

  ) {
    this.id = +this.route.snapshot.paramMap.get("id");

    if (this.id > 0) {
      this.consultaById();
    } else if (this.id === 0) {
      this.dialogSubscription = this._dialogService
        .procuraCPFCNPJ()
        .afterClosed()
        .subscribe(
          data => {
            this.cliente.cnpj = data;
            this.reativar();
          });

    }
  }
  consultaById(): void {
    this.clienteService.readById(this.id).subscribe((res: any) => {
      this.cliente = res.entidade;
      this.cliente.cnpj == null || this.cliente.cnpj == "" ?
        this.iscnpj = false :
        this.iscnpj = true;
      this.cliente.isAtivo === "S" ?
        this.isAtivo = true :
        this.isAtivo = false;
     this.cliente.isRespFinanceiro == 1 ? this.isRespFinanceiro =  "S" : this.isRespFinanceiro =  "N";
    });
  }
  ngOnInit(): void {
   
  }

  consultaCNPJ(cnpj: string): void {
    this.empresaRFService.read(cnpj).subscribe((res: any) => {
      this.empresaRF = res.empresasRF;
    });
    if (this.empresaRF[0].id > 0) {
      this.cliente.nome = this.empresaRF[0].nomefantasia;
      this.cliente.razao = this.empresaRF[0].razaosocial;
      this.cliente.endereco = this.empresaRF.tipoLogradouro + ' ' + this.empresaRF.logradouro;
      this.cliente.enderecoNumero = this.empresaRF.numero;
      this.cliente.complemento = this.empresaRF.complemento;
      this.cliente.bairro = this.empresaRF.bairro;
      this.cliente.cep = this.empresaRF.cep;
      this.cliente.cidade = this.empresaRF.municipio;
      this.cliente.idCidade = this.empresaRF.codMunicipio;
      this.cliente.uf = this.empresaRF.uf;
      this.cliente.fone = this.empresaRF.ddd1 + this.empresaRF.telefone1;
      this.cliente.email = this.empresaRF.email;
      this.cliente.emailBoleto = this.empresaRF.email;
    }
  }

   async saveVindi()  {
    if(this.cliente.isRespFinanceiro == 1){
      this.customer.registry_code = this.cliente.cnpj;
      this.customer.name  = this.cliente.razao;
      this.customer.code  = this.cliente.id.toString();
      this.address.city = this.cliente.cidade;
      this.address.additional_details = this.cliente.complemento;
      this.address.country = 'BR';
      this.address.neighborhood = this.cliente.bairro;
      this.address.number = this.cliente.enderecoNumero;
      this.address.state = this.cliente.uf;
      this.address.street = this.cliente.endereco;
      this.address.zipcode = this.cliente.cep;
      this.customer.address = this.address;
      this.phones.number = '55'+this.cliente.fone;
      this.customer.phones = this.phones;
      this.cliente.isProcessed = 1;
      if(this.cliente.idVindi != "0") {
        await this.customersService.update(this.cliente.idVindi,this.customer).subscribe(() => {});
      } else {
        this.customer.status = 'active';
        await this.customersService.create(this.customer).subscribe((resp) => {
          this.cliente.idVindi = resp.id.toString();
          //Atualiza idVindi
          this.clienteService.update(this.cliente).subscribe(() => {});

        });
      }
    };
  }

  async save() {
    this.cliente.ie = $('#ie').val().replace(/[^\d]+/g,'');
    if(this.isRespFinanceiro == "S"){
      this.cliente.isRespFinanceiro = 1;
    }
    else{
      this.cliente.isRespFinanceiro = 0;
    };
    if (this.cliente.id > 0) {
      await this.clienteService.update(this.cliente).subscribe(() => {
        this.saveVindi();
      })
    } else {
      await this.clienteService.create(this.cliente).subscribe(() => {
        this.saveVindi();
      })
    }; 
    this.dataService.showMessage('Cliente salvo com sucesso!');
    // this.router.navigate(['/clientes']);
    this.location.back();
  }

  cancel(): void {
    // this.router.navigate(['/clientes'])
    this.location.back();
  }

  openDialog() {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: 'Informe o CNPJ',
        buttonText: {
          yes: 'OK',
          no: 'Cancelar'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) { }
    });
  }

  color = 'accent';
  changed(event) {
    event.checked === true ?
      this.reativar() :
      this.desativar();
  }

  reativar() {
    this.cliente.isAtivo = "S";
    this.isAtivo = true;
  }

  desativar() {
    this.cliente.isAtivo = "N";
    this.isAtivo = false;
  }
  
  consultaCEP(cep){
    var self=this;
    $.ajax({
      //O campo URL diz o caminho de onde virá os dados. É importante concatenar o valor digitado no CEP
      url: "https://viacep.com.br/ws/"+ cep +"/json",
      //Aqui você deve preencher o tipo de dados que será lido, no caso, estamos lendo JSON.
      dataType: 'json',
      //SUCESS é referente a função que será executada caso ele consiga ler a fonte de dados com sucesso.
      //O parâmetro dentro da função se refere ao nome da variável que você vai dar para ler esse objeto.
      success: function(resposta){
          //Agora basta definir os valores que você deseja preencher automaticamente nos campos acima.
          self.cliente.endereco = resposta.logradouro;
          self.cliente.complemento = resposta.complemento;
          self.cliente.bairro = resposta.bairro;
          self.cliente.cidade = resposta.localidade;
          self.cliente.uf = resposta.uf;
          self.cliente.idCidade = resposta.ibge;
          //Vamos incluir para que o Número seja focado automaticamente
          $("#nro").focus();
      }
  });
    console.log(cep)
  }
}
export class ConfirmationDialog { }
