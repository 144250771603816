<div class="row" fxLayout="row">
    <div class="col-6"><br>
        <h1 class="title">Clientes</h1>
        <mat-form-field class="search-field" appearance="outline">
            <mat-icon (click)="Search()" matSuffix>search</mat-icon>
            <input matInput placeholder="Pesquise aqui" [(ngModel)]="search">
        </mat-form-field>
    </div>
    <div class="col-6" style="align-items: flex-end;justify-content: flex-end;margin:20px;">
        <button class="botaoPrimario" mat-raised-button (click)="novoVinculo()" fxLayoutAlign="end center">
            Novo Vínculo
        </button> 
    </div>
</div>

<mat-tab-group animationDuration="0ms">
    <mat-tab>
        <div class="mat-elevation-z4">
            <table mat-table [dataSource]="objAtivos">
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef>Código do cliente</th>
                    <td mat-cell *matCellDef="let row">{{row.id}}</td>
                </ng-container>

                <ng-container matColumnDef="nome">
                    <th mat-header-cell *matHeaderCellDef>Nome</th>
                    <td mat-cell *matCellDef="let row">{{row.nome}}</td>
                </ng-container>

                <ng-container matColumnDef="razao">
                    <th mat-header-cell *matHeaderCellDef>Razão Social</th>
                    <td mat-cell *matCellDef="let row">{{row.razao}}</td>
                </ng-container>
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef>Ações</th>
                    <td mat-cell *matCellDef="let row; let i = index">
                        <button class="copy-btn" [matMenuTriggerFor]="menu">
                            <mat-icon matTooltipPosition="right" matTooltipClass="allow-cr" matTooltip="menu">more_vert
                            </mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)='editarCliente(row.id)'>Acessar cliente</button>
                            <button mat-menu-item (click)='desvincular(i)'>Desvincular cliente</button>
                        </mat-menu>
                    </td>

                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsAtivos"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsAtivos;"></tr>
            </table>
        </div>
        <mat-paginator [length]="lengthAtivos" [pageSize]="pageSizeAtivos" [pageSizeOptions]="pageSizeOptionsAtivos"
            (page)="pageEvent = clickPageAtivos($event)">
        </mat-paginator>

    </mat-tab>

</mat-tab-group>