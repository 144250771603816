import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-action-tab-contabilidade',
  templateUrl: './action-tab-contabilidade.component.html',
  styleUrls: ['./action-tab-contabilidade.component.scss']
})
export class ActionTabContabilidadeComponent implements OnInit {

  constructor(private _route: ActivatedRoute) { }
  public id: number; 
  public nome: string = ''; 
  
  ngOnInit(): void {
    this.id = +this._route.snapshot.paramMap.get("id");
  }

  tabClick(index){

  }

  receiverNome(response) {   
    if(response){
     this.nome = response.nome;
    }
  }

}
