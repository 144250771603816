<div *ngIf="true" class="modal" id="modalClientes" role="dialog">

<h1>Vincular cliente</h1>
<div>
  <form class="search-form" style="width: 350px;">

        <mat-label>
            Pesquise o CPF / CNPJ ou Nome
          </mat-label>
          <br>
        <!-- <ng-select2 class="js-data-example-ajax" id="select2Cliente">
          
            <option></option>
            <option>São Pauloooooo</option>
            <option>Rio de Janeiro</option>
            <option>Distrito Federal</option>
            <option>Bahia</option>
            <option>Ceará</option>
            <option>Minas Gerais</option>
            <option>Amazonas</option>
            <option>Pernambuco</option>
            <option>Goiás</option>
            <option>Pará</option>
            <option>Alagoas</option>
            <option>Mato Grosso do Sul</option>
            <option style="width: 100%;">Rio sssssssssssssssssGrande do Norte</option>
            <option>Paraíba</option>
            <option>Santa Catarina</option>
        </ng-select2> -->

        <select class="js-data-example-ajax form-control" id="select2Cliente">
          <option value="">Pesquise o Cfop... </option>
        </select>

  </form>
<br>

      <button class="botaoPrimario" mat-button matSuffix >
        Vincular
      </button>
      <button class="botaoSecundario" mat-button matSuffix (click)="fecharDialog()">
        Cancelar
      </button>

</div>
</div>
