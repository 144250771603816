import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Cliente } from 'src/app/models/cliente.model';
import { ClienteContabil } from 'src/app/models/contabilidade.model';
import { ClienteService } from 'src/app/services/cliente.service';
import { ContabilidadeService } from 'src/app/services/contabilidade.service';
import { GrupoEmpresaService } from 'src/app/services/grupoempresa.service';
import { ProcuraCpfCnpjComponent } from '../procura-cpf-cnpj/procura-cpf-cnpj.component';

interface Escritorio {
  idEntidade: number;
  idEscritorio: number;
}

@Component({
  selector: 'app-vincula-cliente-contabil',
  templateUrl: './vincula-cliente-contabil.component.html',
  styleUrls: ['./vincula-cliente-contabil.component.scss']
})
export class VinculaClienteContabilComponent implements OnInit {

  public form: FormGroup;
  public getSubscription: Subscription;
  clienteContabil: ClienteContabil;
  cliente: any;
  idClienteUsuario: number;
  escritorio: Escritorio;
  clienteExiste: boolean = false;

  constructor(
    private _fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data,
    public snackBar: MatSnackBar,
    private _contabilidadeService: ContabilidadeService,
    private _clienteService: ClienteService,
    public dialogRef: MatDialogRef<ProcuraCpfCnpjComponent>,
    private _grupoEmpresaService: GrupoEmpresaService) {
    this.form = this._fb.group({
      cnpjcpf: ["", Validators.compose([Validators.minLength(1)])]
    });
    this.clienteContabil = {
      idPri: 0,
      idSec: 0
    }
    this.cliente = {
      nome: '',
      cnpj: '',
      razao: ''
    }
    this.escritorio = {
      idEntidade: 0,
      idEscritorio: 0
    }
  }

  ngOnInit(): void {


  }

  consulta() {
      
      if (this.data.tipo === 'E' ) {
        this.consultaCliente();
      }
      else if (this.data.tipo === 'C')
       {
        this.consultaEscritorio();
      }
      else if (this.data.tipo === 'EM') {
        this.consultaEmpresa();
      } else if (this.data.tipo === 'U'){
        this.consultaCliente();
      }
  }

  consultaEscritorio() {
    this.getSubscription = this._contabilidadeService
      .read('A', this.form.controls.cnpjcpf.value, 1, 25).subscribe((res: any) => {
        this.cliente = res.entidade;
        res.entidade.length > 0 ?
          this.clienteExiste = true :
          this.clienteExiste = false;
        this.escritorio.idEntidade = 0;
        this.escritorio.idEscritorio = 0;

      })
  }

  consultaCliente() {
    this.getSubscription = this._clienteService
      .read('A', this.form.controls.cnpjcpf.value, 1, 25).subscribe((res: any) => {
        this.cliente = res.entidade;
        res.entidade.length > 0 ?
          this.clienteExiste = true :
          this.clienteExiste = false;
        this.clienteContabil.idSec = 0;
        this.clienteContabil.idPri = 0;

      })
  }

  consultaEmpresa() {
    this.getSubscription = this._clienteService
      .read('A', this.form.controls.cnpjcpf.value, 1, 25).subscribe((res: any) => {
        this.cliente = res.entidade;
        res.entidade.length > 0 ?
          this.clienteExiste = true :
          this.clienteExiste = false;
        this.clienteContabil.idSec = 0;
        this.clienteContabil.idPri = 0;

      })
  }

  inserirId(id) {
    if (this.data.tipo === 'E' || this.data.tipo === 'EM') {

      this.inserirIdCliente(id);
    }
    else if (this.data.tipo === 'C'){

      this.inserirIdEscritorio(id);
    } 
    else if (this.data.tipo === 'U'){
      this.idClienteUsuario = id;
    }
  }

  inserirIdEscritorio(id) {
    this.escritorio.idEscritorio = id;
    this.escritorio.idEntidade = this.data.idPri
    this.cliente = [];
  }

  inserirIdCliente(id) {
    this.clienteContabil.idSec = id;
    this.clienteContabil.idPri = this.data.idPri
    this.cliente = [];
  }


  vincular() {
    let autohide = 3000;

    if (this.data.tipo === 'E' ) {
      this.getSubscription = this._grupoEmpresaService
        .createClienteContabil(this.clienteContabil).subscribe((res: any) => {
          this.snackBar.open(res.message, undefined, {
            duration: autohide,
            panelClass: ['ok-snackbar']
          });
          this.fecharDialog();
        })
    } else if (this.data.tipo === 'C' ) {
      this.getSubscription = this._clienteService
        .createClienteEscritorio(this.escritorio).subscribe((res: any) => {
          this.snackBar.open(res.message, undefined, {
            duration: autohide,
            panelClass: ['ok-snackbar']
          });
          this.fecharDialog();
        })
    } else if( this.data.tipo === 'EM' ){
      this.getSubscription = this._grupoEmpresaService
      .createClienteContabil(this.clienteContabil).subscribe((res: any) => {
        this.snackBar.open(res.message, undefined, {
          duration: autohide,
          panelClass: ['ok-snackbar']
        });
        this.fecharDialog();
      })
    }
    else if (this.data.tipo === 'U'){
      this.dialogRef.close(this.idClienteUsuario);
    }
  }

  fecharDialog() {
    this.dialogRef.close(false);
  }
}
