import { Injectable } from '@angular/core';
import { MatSnackBar } from "@angular/material/snack-bar";
import { HttpClient,HttpHeaders } from "@angular/common/http";
import { Cliente } from  '../models/cliente.model';
import { Observable, EMPTY } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { DataService } from './data.service';


@Injectable({
  providedIn: 'root'
})
export class ClienteService {
  
  constructor(private service: DataService, private snackBar: MatSnackBar, 
              private http: HttpClient,
              ) { }
  
  
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'})
  }
  
  totalActive = 0;
  totalInactive = 0;

  create(cliente): Observable<Cliente> {
    let resp = this.http.post<Cliente>(this.service.baseUrl+'/entidade', cliente,this.httpOptions); 
    return resp;
  }

  read(status: String, search: String, page: number, sizepage: number): Observable<Cliente[]> {
    let searchLocal: String = '';
    if(search != ''){
      searchLocal = '&search='+search;
    }; 
    return this.http.get<Cliente[]>(this.service.baseUrl+'/entidade?status='+status+
                '&isClienteteste=N'+
                searchLocal+
                '&page='+page+
                '&limit='+sizepage,
                this.httpOptions);
  }

  readTeste(status: String, search: String, page: number, sizepage: number): Observable<Cliente[]> {
    let searchLocal: String = '';
    if(search != ''){
      searchLocal = '&search='+search;
    }; 
    return this.http.get<Cliente[]>(this.service.baseUrl+'/entidade?status='+status+
                '&isClienteteste=S'+
                searchLocal+
                '&page='+page+
                '&limit='+sizepage,
                this.httpOptions);
  }

  readById(id: number): Observable<Cliente> {
    return this.http.get<Cliente>(this.service.baseUrl+'/entidade/'+id, this.httpOptions);
    // const url = `${this.service.baseUrl}/entidade/${id}`;
    // return this.http.get<Cliente>(url).pipe(
    //   map((obj) => obj),
    //   catchError((e) => this.errorHandler(e))
//    );
  }

  update(cliente): Observable<Cliente> {
    const url = `${this.service.baseUrl}/entidade/${cliente.id}`;
    let resp = this.http.put<Cliente>(url, cliente,this.httpOptions).pipe(
      map((obj) => obj),
      catchError((e) => this.errorHandler(e)));
    return resp;

  }

  delete(id: number): Observable<Cliente> {
    const url = `${this.service.baseUrl}/${id}`;
    return this.http.delete<Cliente>(url,this.httpOptions).pipe(
      map((obj) => obj),
      catchError((e) => this.errorHandler(e))
    );
  }

  errorHandler(e: any): Observable<any> {
    this.service.showMessage("Ocorreu um erro!", true);
    return EMPTY;
  }

  readPctAcesso(id: number) {
    return this.http.get
    (this.service.baseUrl+'/entidadePctacesso?idEntidade='+id,this.httpOptions);
  }

  createPctAcesso(data) {
    return this.http.post(this.service.baseUrl+'/entidadePctacesso', data,this.httpOptions);
  }

  deletePctAcesso(id: number) {
    const url = `${this.service.baseUrl + '/entidadePctacesso'}/${id}`;
    return this.http.delete(url,this.httpOptions).pipe(
      map((obj) => obj),
      catchError((e) => this.errorHandler(e))
    );
  }
  readGrupoEmpresa(id: number) {
    return this.http.get
    (this.service.baseUrl+'/grupoempresa?idPri='+id,this.httpOptions);
  }
  
  readReajuste(id: number) {
    return this.http.get
    (this.service.baseUrl+'/entidadeReajuste?idEntidade='+id,this.httpOptions);
  }

  readNfsByDate(id: number, date1, date2) {
    return this.http.get
    (this.service.baseUrl+"/NFServico?idEntidade="+id+"&data1='"+date1+"'&data2='"+date2+"'",this.httpOptions);
  }

  readNfs(id: number) {
    return this.http.get
    (this.service.baseUrl+"/NFServico?idEntidade="+id,this.httpOptions);
  }
  
  readClienteEscritorio(id: number) {
    return this.http.get
    (this.service.baseUrl+'/entidadeEscritorio?idEntidade='+id,this.httpOptions);
  }

  createClienteChaveAcesso(data) {
    return this.http.post(this.service.baseUrl+'/entidade/gerarChave', data,this.httpOptions);
  }

  createClienteEscritorio(data) {
    return this.http.post(this.service.baseUrl+'/entidadeEscritorio', data,this.httpOptions);
  }

  updateClienteEscritorio(data, id) {
    return this.http
    .put(`${this.service.baseUrl}/entidadeEscritorio/${id}`, data,this.httpOptions)
  }

  desativarCliente(id: number){
    let data = {
      "isAtivo" : "N" 
    }
    const url = `${this.service.baseUrl + '/entidade'}/${id}`;
    return this.http.put(url, data,this.httpOptions).pipe(
      map((obj) => obj),
      catchError((e) => this.errorHandler(e))
    );
  }

  reativarCliente(id: number){
    let data = {
      "isAtivo" : "S" 
    }
    const url = `${this.service.baseUrl + '/entidade'}/${id}`;
    return this.http.put(url, data,this.httpOptions).pipe(
      map((obj) => obj),
      catchError((e) => this.errorHandler(e))
    );
  }

  ativarClienteTeste(id: number){
    let data = {
      "isClienteteste" : "N" 
    }
    const url = `${this.service.baseUrl + '/entidade'}/${id}`;
    return this.http.put(url, data,this.httpOptions).pipe(
      map((obj) => obj),
      catchError((e) => this.errorHandler(e))
    );
  }

}
