import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Contabilidade } from 'src/app/models/contabilidade.model';
import { ContabilidadeService } from 'src/app/services/contabilidade.service';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-edit-contabilidade',
  templateUrl: './edit-contabilidade.component.html',
  styleUrls: ['./edit-contabilidade.component.scss']
})
export class EditContabilidadeComponent implements OnInit, OnDestroy {

  isAtivo: boolean;

  public id: number;
  public dialogSubscription: Subscription;
  public pSubscription: Subscription;
  public form: FormGroup;
  public objCliente: Contabilidade;
  objSubmit: Contabilidade;
  public iscnpj: boolean = true;
  @Output() receiverNome = new EventEmitter();

  constructor(
    private _contabilidadeService: ContabilidadeService,
    private _dialogService: DialogService,
    private router: Router,
    public snackBar: MatSnackBar,
    private _fb: FormBuilder,
    private route: ActivatedRoute
  ) {
    this.form = this._fb.group({
      nome: [
        "",
        Validators.compose([Validators.required])
      ],
      razao: [
        "",
        Validators.compose([Validators.required])
      ],
      endereco: [
        "",
        Validators.compose([Validators.required])
      ],
      enderecoNumero: [
        "",
        Validators.compose([Validators.required])
      ],
      complemento: [
        "",
        Validators.compose([Validators.required])
      ],
      bairro: [
        "",
        Validators.compose([Validators.required])
      ],
      cidade: [
        "",
        Validators.compose([Validators.required])
      ],
      cep: [
        "",
        Validators.compose([Validators.required])
      ],
      uf: [
        "",
        Validators.compose([Validators.required])
      ],
      cnpj: [
        "",
        Validators.compose([])
      ],
      cpf: [
        "",
        Validators.compose([Validators.required])
      ],
      ie: [
        "",
        Validators.compose([Validators.required])
      ],
      rg: [
        "",
        Validators.compose([])
      ],
      fone: [
        "",
        Validators.compose([Validators.required])
      ],
      email: [
        "",
        Validators.compose([Validators.required])
      ],
      contato: [
        "",
        Validators.compose([Validators.required])
      ]
    });
    this.objCliente = {
      nome: '',
      razao: '',
      endereco: '',
      enderecoNumero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      cep: '',
      uf: '',
      cnpj: '',
      cpf: '',
      ie: '',
      rg: '',
      isEscritorio: '',
      ativo: '',
      fone: '',
      email: '',
      contato: '',
      isAtivo:''
    }
    this.id = +this.route.snapshot.paramMap.get("id");

    if (this.id === 0) {
      this.dialogSubscription = this._dialogService
        .procuraCPFCNPJ()
        .afterClosed()
        .subscribe(
          data => {
            this.objCliente.cnpj = data;
            this.isAtivo = true;
            this.objCliente.isAtivo = 'S'
          });
    } else {
      this.consultaById();
    }
  }

  ngOnDestroy(): void {
    if (this.dialogSubscription) {
      this.dialogSubscription.unsubscribe();
    }
    if (this.pSubscription) {
      this.pSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {

  }

  consultaById(): void {
    this._contabilidadeService.readById(this.id).subscribe((res: any) => {
      this.objCliente = res.entidade;
      setTimeout(() => {
        this.receiverNome.emit({ nome: this.objCliente.nome });
      }, 100);
      this.objCliente.cpf == null || this.objCliente.cpf == "" ?
        this.iscnpj = true :
        this.iscnpj = false;
        this.objCliente.isAtivo === "S" ? 
        this.isAtivo = true : 
        this.isAtivo = false;
    });
  }

  voltar() {
    this.router.navigateByUrl("/contabilidade");
  }

  submit() {
    let autohide = 3000;
    this.objSubmit = this.form.value;
    this.objSubmit.isEscritorio = "S";
    this.objSubmit.isAtivo = this.objCliente.isAtivo;

    if (this.id === 0) {
      this.pSubscription = this._contabilidadeService.create(this.objSubmit).subscribe((res: any) => {
        this.snackBar.open(res.message, undefined, {
          duration: autohide,
          panelClass: ['ok-snackbar']
        });
        this.voltar();
      });
    } else {
      this.pSubscription = this._contabilidadeService.update(this.objSubmit, this.id).subscribe((res: any) => {
        this.snackBar.open(res.message, undefined, {
          duration: autohide,
          panelClass: ['ok-snackbar']
        });
        this.voltar();
      });
    }
  }

  color = 'accent';
  changed(event) {
    event.checked === true ?
      this.reativar() :
      this.desativar();
  }
  
  reativar() {
    this.isAtivo = true;
    this.objCliente.isAtivo = "S";
  }

  desativar() {
    this.isAtivo = false;
    this.objCliente.isAtivo = "N";
  }

}
