import { Injectable } from '@angular/core';
import { MatSnackBar } from "@angular/material/snack-bar";
import { HttpClient } from "@angular/common/http";
import { Fechamento } from  '../models/fechamento.model';
import { Observable, EMPTY } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})


export class FechamentoService {
 
  constructor(private service: DataService, private snackBar: MatSnackBar, private http: HttpClient) { }
  
  totalActive = 0;
  totalInactive = 0;

  create(fechamento ): Observable<Fechamento> {
    return this.http.post<Fechamento>(this.service.baseUrl+'/fechamento', fechamento);
  }

  read(page: number, sizepage: number, mes: number, ano: number): Promise<Fechamento[]> {
    return this.http.get<Fechamento[]>(this.service.baseUrl+'/fechamento'+
                '?mes='+mes+
                '&ano='+ano+
                '&page='+page+
                '&limit='+sizepage)
                .toPromise();
  }

  readById(id: number): Observable<Fechamento> {
    return this.http.get<Fechamento>(this.service.baseUrl+'/fechamento/'+id);
  }

  periodo(): Observable<Fechamento> {
    return this.http.get<Fechamento>(this.service.baseUrl+'/fechamento/periodo');
  }

  errorHandler(e: any): Observable<any> {
    this.service.showMessage("Ocorreu um erro!", true);
    return EMPTY;
  }
}
