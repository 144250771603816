import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { LoginService } from 'src/app/services/login.service';
import { Security } from 'src/app/services/login.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  
  autohide: 3000;
  userLogged: FormControl;
  postSubscription:Subscription; 
  form: FormGroup;

  constructor(
    private fb: FormBuilder, 
    private router: Router,
    public snackBar: MatSnackBar,
    private _login:LoginService
  ) { 
    
    this.form = this.fb.group({
      login: [
        "",
        Validators.compose([
          Validators.minLength(1),
          Validators.required
        ])
      ],
      senha: [
        "",
        Validators.compose([
          Validators.minLength(1),
          Validators.required
        ])
      ],
      userLoggedForm: ''
    });
  }

  ngOnInit(): void {
    const token = Security.getToken();
    if (token) {
      this.router.navigateByUrl('/home');
    }

  }

  submit(){
    let data = {
      login: this.form.controls.login.value,
      senha: this.form.controls.senha.value
    }
    this.postSubscription = this._login.create(data).subscribe(
      (res:any)=>{
        if(res)
        this.setUser(data, 'ok');

      } , (err)  =>{ 
        this.snackBar.open(err.error.message, undefined, {
        duration: this.autohide,
        panelClass: ['err-snackbar']
      });
      }
    )
      return true;
  }

  setUser(user, token) {
    const connUser = this.userSession();
    if(connUser == true){
      Security.set(user, token);
    } else {
      Security.setSession(user, token);
    }
    
    this.router.navigate(['/home']);
  }

  userSession(){
    console.log(this.form.controls.userLoggedForm.value )

    if(this.form.controls.userLoggedForm.value == true){
      return true;
    }else {
      return false;
    }
  }

  logout() {
    Security.clear();
    this.router.navigate(['/login']);
}

}
