import { Injectable } from '@angular/core';
import { MatSnackBar } from "@angular/material/snack-bar";
import { HttpClient, HttpHeaders  } from "@angular/common/http";
import { NFServico } from  '../models/nfservico.model';

import { DataService } from './data.service';
import { Observable, EMPTY } from "rxjs";
import { map, catchError } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class NfservicoService {
  

  constructor(private service: DataService, private snackBar: MatSnackBar, private http: HttpClient) { }

  // read(): Observable<NFServico[]> {
  //   return this.http.get<NFServico[]>(this.service.baseUrl+'/getAllNFServico').pipe(
  //     map((obj) => obj['result'][0]),
  //     catchError((e) => this.errorHandler(e))
  //   );
  // }
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'})
  }

  read(status: String,search: String, page:number, sizepage: number, date1 :String, date2:String): Observable<NFServico[]> {
    let searchLocal: String = '';
    let dateFilter: String = '';
    if(search != ''){
      searchLocal = '&search='+search;
    }
    if((date1 != '') && (date2 != '')){
      dateFilter = "&data1='"+date1+"'&data2='"+date2+"'"
    }

    return this.http.get<NFServico[]>(this.service.baseUrl+'/nfservico/?status='+status+searchLocal+'&page='+page+'&limit='+sizepage+dateFilter, this.httpOptions);

  }

  readByCliente(idCliente: number): Observable<NFServico[]> {
    return this.http.get<NFServico[]>(this.service.baseUrl+'/nfservico?idCliente='+idCliente, this.httpOptions).pipe(
      map((obj) => obj),
      catchError((e) => this.errorHandler(e))
    );
  }
  async enviarEmailNFSe(id: number,email: String): Promise<any>  {
    let nfsEmail = {
      id: id,
      email: email
    };

    this.http.post(this.service.baseUrl+'/nfse/emailNFSe', nfsEmail, this.httpOptions)
   //   .toPromise(),
      .subscribe((res) => {
        console.log(res);
        this.service.showMessage("e-mail para "+ email + ", enviado com sucesso!", false);

      })
      //catchError((e) => this.errorHandler(e));

      // subscribe((obj) => obj['result'][0]),
      // catchError((e) => this.errorHandler(e));
   //    this.service.showMessage("e-mail enviado com sucesso!", false);
    }

  async enviarNFSe(id: number): Promise<any>  {
      let nfse = {
        id: id
      };
      return await this.http.post(this.service.baseUrl+'/nfse/enviarNFSe', nfse, this.httpOptions)
      .subscribe((res: any) => {
 //       console.log(res);
        if(res.status === 100 ){
          // this.service.showMessage(res.msg, false);
          alert(res.msg);
           location.reload();

        } else {
          this.service.showMessage(res.msg, true);
        }

      })//       toPromise(),
 //       catchError((e) => this.errorHandler(e));
       catchError((e) => this.errorHandler(e));
}
    

 async imprimirNFSe(id: number): Promise<any>   {
   let printNFSe = {
     id: id
   }
     this.http.post(this.service.baseUrl+'/nfse/imprimirNFSe', printNFSe, this.httpOptions).subscribe((res:any)=>{
      console.log(res.urlPdf);
      window.open(res.urlPdf);
    });
        //window.open("http://cpro28883.publiccloud.com.br:8081/getFile?filename=3520072014152800019856000000000016206-nfse.pdf");
  }

    errorHandler(e: any): Observable<any> {
    this.service.showMessage("Ocorreu um erro!", true);
    return EMPTY;
  }


}
