<BR><BR><BR>
<mat-card class="home mat-elevation-z3">
    <mat-card-title class="title">
        Kiron ADM
    </mat-card-title>
    <mat-card-subtitle class="subtitle">
        Sistema para Controle Administrativo
    </mat-card-subtitle>

    <!-- OPÇÃO 2 -->
    <!-- <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="card-box bg-blue">
                    <div class="inner">
                        <h3> 253 </h3>
                        <p> Kiron Consulta </p>
                    </div>
                    <div class="icon">
                        <i class="material-icons" aria-hidden="true">
                            plagiarism
                        </i>
                    </div>
                    <a href="#" class="card-box-footer">Ver Mais + </a>
                </div>
            </div>
    
            <div class="col-lg-3 col-sm-6">
                <div class="card-box bg-green">
                    <div class="inner">
                        <h3> 178 </h3>
                        <p> Kiron Notas </p>
                    </div>
                    <div class="icon">
                        <i class="material-icons" aria-hidden="true">
                            request_quote
                        </i>
                    </div>
                    <a href="#" class="card-box-footer">Ver Mais + </a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="card-box bg-orange">
                    <div class="inner">
                        <h3> 315 </h3>
                        <p> Kiron Gestor </p>
                    </div>
                    <div class="icon">

                        <i class="material-icons" aria-hidden="true">
                            miscellaneous_services
                        </i>

                    </div>
                    <a href="#" class="card-box-footer">Ver Mais +</a>
                </div>
            </div>
            
        </div>
        </div> -->
   
      
      <!-- OPÇÃO 1 -->
        <!-- <div class="row1-container">
        <div class="box card-1">
            <h2 class="h2">Kiron Consulta</h2>
            <h1 class="h1">220</h1>
            <img src="https://assets.codepen.io/2301174/icon-supervisor.svg" alt="">
        </div>

        <div class="box card-5">
          <h2 class="h2">Kiron Notas</h2>
          <h1 class="h1">250</h1>
          <img src="https://assets.codepen.io/2301174/icon-karma.svg" alt="">
        </div>
    
        <div class="box card-3">
          <h2 class="h2">Kiron Gestor</h2>
          <h1 class="h1">143</h1>
          <img src="https://assets.codepen.io/2301174/icon-calculator.svg" alt="">
        </div>
      

    </div> -->


    <!-- OPÇÃO 3 -->
    <div class="row">
        <div class="row ">
           
            <div class="col-xl-3 col-lg-6">
                <div class="card l-bg-green-dark">
                    <div class="card-statistic-3 p-4">
                        <div class="card-icon card-icon-large"><i class="fas fa-file-invoice-dollar"></i></div>
                        <div class="mb-4">
                            <h5 class="fontTitle">Kiron Notas</h5>
                        </div>
                        <div class="row align-items-center mb-2 d-flex">
                            <div class="col-8">
                                <h2 class="fontSub">
                                    {{dashboard.notas}}
                                </h2>
                            </div>
                            <div class="fontEstatistica">
                                <!-- <span>9.23% <i class="fa fa-arrow-up"></i></span><br>
                                <span>Nesse mês</span> -->
                            </div>
                        </div>
                        <div class="progress mt-1 " data-height="8" style="height: 8px;">
                            <div class="progress-bar l-bg-green" role="progressbar" data-width="25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style="width: 25%;"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-6">
                <div class="card l-bg-cherry">
                    <div class="card-statistic-3 p-4">
                        <div class="card-icon card-icon-large"><i class="fas fa-user-tie"></i></div>
                        <div class="mb-4">
                            <h5 class="fontTitle">Kiron Gestor</h5>
                        </div>
                        <div class="row align-items-center mb-2 d-flex">
                            <div class="col-8">
                                <h2 class="fontSub">
                                    {{dashboard.gestor}}
                                </h2>
                            </div>
                            <div class="fontEstatistica">
                                <!-- <span>10% <i class="fa fa-arrow-up"></i></span><br>
                                <span>Nesse mês</span> -->
                            </div>
                        </div>
                        <div class="progress mt-1 " data-height="8" style="height: 8px;">
                            <div class="progress-bar l-bg-orange" role="progressbar" data-width="25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style="width: 25%;"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-6">
                <div class="card l-bg-blue-dark">
                    <div class="card-statistic-3 p-4">
                        <div class="card-icon card-icon-large"><i class="fas fa-search"></i></div>
                        <div class="mb-4">
                            <h5 class="fontTitle">Kiron Consulta</h5>
                        </div>
                        <div class="row align-items-center mb-2 d-flex">
                            <div class="col-8">
                                <h2 class="fontSub">
                                    {{dashboard.consulta}}
                                </h2>
                            </div>
                            <div class="fontEstatistica">
                                <!-- <span>12.5% <i class="fa fa-arrow-up"></i><br></span>
                                <span>Nesse mês</span> -->
                            </div>
                        </div>
                        <div class="progress mt-1 " data-height="8" style="height: 8px;">
                            <div class="progress-bar l-bg-cyan" role="progressbar" data-width="25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style="width: 25%;"></div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>



</mat-card>
