<br><br><br>
<table mat-table [dataSource]="objAtivos">
    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>Código</th>
        <td mat-cell *matCellDef="let row">{{row.id}}</td>
    </ng-container>

    <ng-container matColumnDef="nome">
        <th mat-header-cell *matHeaderCellDef>Nome</th>
        <td mat-cell *matCellDef="let row">{{row.nome}}</td>
    </ng-container>

    <ng-container matColumnDef="razao">
        <th mat-header-cell *matHeaderCellDef>Razão</th>
        <td mat-cell *matCellDef="let row">{{row.razao}}</td>
    </ng-container>

    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let row">
            <p class="status status-1" *ngIf="row.isRespFinanceiro == 1">
                responsável financeiro
            </p>
        </td>
    </ng-container>

    <ng-container matColumnDef="acao" class="col-3" style="align-items: flex-end; padding: 15px;">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row; let i = index">
            <button class="delete-btn"  (click)="desvincular(i)">
                <mat-icon
                        matTooltipPosition="right"
                        matTooltipClass="allow-cr"
                        matTooltip="Desvincular">delete</mat-icon>
            </button>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumnsAtivos"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsAtivos;"></tr>
</table>

<div class="row">
    <div class="col-6">
        <button style="margin-top:15px;" class="botaoSecundario" mat-raised-button (click)="fecharDialog()"
            fxLayoutAlign="end center">
            Fechar
        </button>
    </div>
    <div class="col-6" style="align-items: flex-end; justify-content: center ;">
        <button class="botaoPrimario" style="margin-top:15px;" mat-raised-button (click)="vincular()">
            Vincular Empresa
        </button>
    </div>
</div>