import { Injectable } from '@angular/core';
import { MatSnackBar } from "@angular/material/snack-bar";
import { HttpClient, HttpHeaders  } from "@angular/common/http";
import { UsuarioEntidade } from  '../models/usuarioentidade.model';

import { DataService } from './data.service';
import { Observable, EMPTY } from "rxjs";
import { map, catchError } from "rxjs/operators";


@Injectable({
    providedIn: 'root'
  })

export class UsuarioEntidadeService {
    constructor(private service: DataService, private snackBar: MatSnackBar, private http: HttpClient) { }
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json'})
      }
    getByCliente(idCliente: number): Observable<UsuarioEntidade[]> {
        return this.http.get<UsuarioEntidade[]>(this.service.baseUrl+'/UsuarioEntidade?idCliente='+idCliente, this.httpOptions
    )};
    
    
}