import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EmpresasRF } from  '../models/empresasRF.model';
import { Observable } from "rxjs";
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class EmpresasRFService {
  
  constructor(private service: DataService, private http: HttpClient) { }
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'})
  }

  read(search: String): Observable<EmpresasRF[]> {
    return this.http.get<EmpresasRF[]>(this.service.baseUrl+'/empresasRF?search='+search, this.httpOptions);
  }
}
