<mat-card class="card-dados" *ngIf="data.acao == 'add'">
    <h1 class="title">Vincular usuário</h1>
    <form [formGroup]="form" class="search-form">
        <mat-form-field  appearance="outline" style="width: 100%;">
            <label>
                Email
            </label>
            <mat-icon (click)="consultaUsuario()" matSuffix>search</mat-icon>
            <input matInput formControlName="email" [matAutocomplete]="auto">
        </mat-form-field>
        <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let option of usuarios" (onSelectionChange)="inserirId(option.id)"
                [value]="option.login">
                {{option.login}}
            </mat-option>
        </mat-autocomplete>

    </form>
    <button class="botaoPrimario" [disabled]="disabledButton" mat-raised-button (click)="criarUsuarioContabil()">
        Vincular
    </button>
    <button class="botaoSecundario" style="margin-right:15px;" mat-raised-button (click)="voltar()">
        Voltar
    </button><br><br>

</mat-card>
<mat-card class="card-dados" *ngIf="data.acao == 'update' || data.acao == 'usuarioUpdate'">
    <div class="row">
        <div class="col-6">
            <h1 class="title">Gerenciar usuário</h1>
            <button class="botaoPrimario" mat-raised-button (click)="resetarSenha()">
                Senha Padrão
            </button>
        </div>
        <div class="col-6" style="align-items: flex-end;justify-content: flex-end; min-height: 80px;">
            <!-- <button *ngIf="vincClientes == false || vincClientesUsuario === true" class="botaoPrimario" mat-raised-button
                (click)="getClientesEscritorio()"> -->
           <button *ngIf="vincClientes == false || vincClientesUsuario === true" class="botaoPrimario" mat-raised-button
           (click)="openDialog()">
                Vincular Clientes
            </button>
            <button *ngIf="vincClientes == true " class="btn-primary" mat-raised-button
                (click)="getAtivos()">
                Voltar
            </button>
        </div>
    </div>
    <br>

    <mat-tab-group animationDuration="5ms">
        <mat-tab >
            <ng-template mat-tab-label>
                <span>Usuários Portal</span>
              </ng-template>
            <div class="mat-elevation-z4">
                <table mat-table [dataSource]="objAtivos">
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef>Código</th>
                        <td mat-cell *matCellDef="let row">{{row.id}}</td>
                    </ng-container>
                    <ng-container matColumnDef="nome">
                        <th mat-header-cell *matHeaderCellDef>Nome</th>
                        <td mat-cell *matCellDef="let row">{{row.nome}}</td>
                    </ng-container>

                    <ng-container matColumnDef="razao">
                        <th mat-header-cell *matHeaderCellDef>Razão</th>
                        <td mat-cell *matCellDef="let row">{{row.razao}}</td>
                    </ng-container>
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef>
                            <span *ngIf="vincClientes == false">Ações</span>
                        </th>
                        <td mat-cell *matCellDef="let row; let i = index">
                            <button class="delete-btn" *ngIf="vincClientes == false  && vincClientesUsuario == false"
                                (click)="desvincularCliente(i)">
                                <mat-icon
                                matTooltipPosition="right"
                                matTooltipClass="allow-cr"
                                matTooltip=" Desvincular cliente">delete</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="checkbox">
                        <th mat-header-cell *matHeaderCellDef><span *ngIf="vincClientes == true || vincClientesUsuario == true">Vincular Cliente</span></th>
                        <td mat-cell *matCellDef="let row; let i = index">
                            <mat-checkbox #checkbox *ngIf="vincClientes == true  || vincClientesUsuario == true"
                            [(ngModel)]="checked[i]" 
                                (change)='showOptions(checkbox.checked, row.id, i)' value=''>
                                <span *ngIf="checked[i] != true">Vincular</span>
                                <span *ngIf="checked[i] == true">Desvincular</span>
                            </mat-checkbox>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsAtivos"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsAtivos;"></tr>
                </table>
            </div>
            <mat-paginator [length]="lengthAtivos" [pageSize]="pageSizeAtivos" [pageSizeOptions]="pageSizeOptionsAtivos"
                (page)="pageEvent = clickPageAtivos($event)">
            </mat-paginator>
        </mat-tab>
        <mat-tab >
            <ng-template mat-tab-label>
                <span>Usuários Kiron Notas</span>
              </ng-template>
            <div class="mat-elevation-z4">
                <table mat-table [dataSource]="objAtivos">
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef>Código</th>
                        <td mat-cell *matCellDef="let row">
                            Teste
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="nome">
                        <th mat-header-cell *matHeaderCellDef>Nome</th>
                        <td mat-cell *matCellDef="let row">
                            Teste
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="razao">
                        <th mat-header-cell *matHeaderCellDef>Razão</th>
                        <td mat-cell *matCellDef="let row">
                            Teste
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef>
                            <span *ngIf="vincClientes == false">Ações</span>
                        </th>
                        <td mat-cell *matCellDef="let row; let i = index">
                            <button class="delete-btn" *ngIf="vincClientes == false  && vincClientesUsuario == false"
                                (click)="desvincularCliente(i)">
                                <mat-icon
                                matTooltipPosition="right"
                                matTooltipClass="allow-cr"
                                matTooltip=" Desvincular cliente">delete</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="checkbox">
                        <th mat-header-cell *matHeaderCellDef><span *ngIf="vincClientes == true || vincClientesUsuario == true">Vincular Cliente</span></th>
                        <td mat-cell *matCellDef="let row; let i = index">
                            <mat-checkbox #checkbox *ngIf="vincClientes == true  || vincClientesUsuario == true"
                            [(ngModel)]="checked[i]" 
                                (change)='showOptions(checkbox.checked, row.id, i)' value=''>
                                <span *ngIf="checked[i] != true">Vincular</span>
                                <span *ngIf="checked[i] == true">Desvincular</span>
                            </mat-checkbox>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsAtivos"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsAtivos;"></tr>
                </table>
            </div>
            <mat-paginator [length]="lengthAtivos" [pageSize]="pageSizeAtivos" [pageSizeOptions]="pageSizeOptionsAtivos"
                (page)="pageEvent = clickPageAtivos($event)">
            </mat-paginator>
        </mat-tab>

        <!-- TAREFA DO BACKLOG -->


    </mat-tab-group>
    <button class="botaoSecundario" mat-raised-button (click)="voltar()">
        Fechar
    </button><br><br><br>
</mat-card>