import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoginService } from './login.service';
import { Security } from './login.service';

@Injectable()
export class AuthService implements CanActivate{

  constructor(private router: Router, private _login: LoginService) { }

  postSubscription:Subscription; 
  
  canActivate(){
    
    const token = Security.getToken();
   
   
    if(!token){
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }

}