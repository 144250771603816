import { Injectable } from '@angular/core';
import { MatSnackBar } from "@angular/material/snack-bar";
import { HttpClient,HttpEvent,HttpHandler,HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Charges } from  '../models/vindi.charges.model';

import { DataService } from './data.service';
import { Observable, EMPTY } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})

export class ChargesService {

      
  constructor(private service: DataService, private snackBar: MatSnackBar, private http: HttpClient) {}
  

  getChargesByPaid(idVindi: string, date1: string, date2:string): Observable<Charges[]> {
    return this.http.get<Charges[]>
    (this.service.baseUrl+'/vindi/charges?customer_id='+idVindi+'&paid_at1='+ date1 + '&paid_at2=' + date2).pipe(
      map((obj) => obj['charges']),
      catchError((e) => this.errorHandler(e))
    );
  }
  getChargesByCliente(idVindi: string): Observable<Charges[]> {
    return this.http.get<Charges[]>
    (this.service.baseUrl+'/vindi/charges?customer_id='+idVindi).pipe(
      map((obj) => obj['charges']),
      catchError((e) => this.errorHandler(e))
    );
  }
  errorHandler(e: any): Observable<any> {
    this.service.showMessage("Ocorreu um erro! " + e.catchError, true);
    return EMPTY;
  }
 
}
