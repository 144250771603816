import { Injectable } from "@angular/core";
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../components/template/modal/confirm-dialog/confirm-dialog.component';
import { ProcuraCpfCnpjComponent } from '../components/template/modal/procura-cpf-cnpj/procura-cpf-cnpj.component';
import { VinculaClienteContabilComponent } from '../components/template/modal/vincula-cliente-contabil/vincula-cliente-contabil.component';
import { ListGrupoEmpresaComponent } from '../views/cliente/edit/list-grupo-empresa/list-grupo-empresa.component';
import { ModalChaveAcessoComponent } from '../views/cliente/info/modal-chave-acesso/modal-chave-acesso.component';
import { EditUsuarioContabilComponent } from '../views/contabilidade/action/edit-usuario-contabil/edit-usuario-contabil.component';

@Injectable({
  providedIn: "root"
})


export class DialogService {
  constructor(private dialog: MatDialog) { }


  openConfirmDialog(msg, botao) {
    return this.dialog.open(ConfirmDialogComponent, {
      position: { top: '150px' },
      width: '390px',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        message: msg,
        botao: botao
      }
    });
  }

  procuraCPFCNPJ() {
    return this.dialog.open(ProcuraCpfCnpjComponent, {
      position: { top: '50px' },
      width: '800px',
      panelClass: 'form-dialog-container',
      disableClose: true,
    });
  }

  vinculaClienteEscritorio(idPri, tipo) {
    return this.dialog.open(VinculaClienteContabilComponent, {
      position: { top: '50px' },
      width: '500px',
      panelClass: 'form-dialog-container',
      disableClose: true,
      data :{
        idPri : idPri,
        tipo: tipo
      }
    });
  }

  gerenciarUsuario(idCliente, acao, idUsuario) {
    return this.dialog.open(EditUsuarioContabilComponent, {
      position: { top: '50px' },
      maxHeight: '90vh',
      width: '900px',
      panelClass: 'form-dialog-container',
      disableClose: true,
      data:{
        idCliente: idCliente,
        acao: acao,
        idUsuario: idUsuario    
      }
    });
  }

  visualizarGrupoEmpresas(id) {
    return this.dialog.open(ListGrupoEmpresaComponent, {
      position: { top: '50px' },
      width: '900px',
      panelClass: 'form-dialog-container',
      disableClose: true,
      data:{
        id:id
      }
    });
  }
  gerarChaveACesso(id, cnpj) {
    return this.dialog.open(ModalChaveAcessoComponent, {
      position: { top: '50px' },
      width: '350px',
      panelClass: 'form-dialog-container',
      disableClose: true,
      data:{
        id:id,
        cnpj:cnpj
      }
    });
  }

  
}