import { Injectable } from '@angular/core';
import { MatSnackBar } from "@angular/material/snack-bar";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Permissoes } from  '../models/permissoes.model';

import { DataService } from './data.service';
import { Observable, EMPTY } from "rxjs";
import { map, catchError } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class PermissoesService {
  

  constructor(private service: DataService, private snackBar: MatSnackBar, private http: HttpClient) { }
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'})
  }

  readByEntidade(idEntidade: number): Observable<Permissoes[]> {
    return this.http.get<Permissoes[]>(this.service.baseUrl+'/permissoes?idEntidade='+idEntidade, this.httpOptions).pipe(
      map((obj) => obj),
      catchError((e) => this.errorHandler(e))
    );
  }

  readByUsuario(idUsuario: number): Observable<Permissoes[]> {
    return this.http.get<Permissoes[]>(this.service.baseUrl+'/permissoes?idUsuario='+idUsuario, this.httpOptions).pipe(
      map((obj) => obj),
      catchError((e) => this.errorHandler(e))
    );
  }


    errorHandler(e: any): Observable<any> {
    this.service.showMessage("Ocorreu um erro!", true);
    return EMPTY;
  }


}
