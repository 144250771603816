import { MatDialog } from '@angular/material/dialog';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { FechamentoService } from 'src/app/services/fechamento.service';
import { DialogService } from 'src/app/services/dialog.service';
import { Fechamento } from '../../models/fechamento.model';
import { ModalCriarFechamentoComponent } from './modal-criar-fechamento/modal-criar-fechamento.component';

@Component({
  selector: 'app-fechamento',
  templateUrl: './fechamento.component.html',
  styleUrls: ['./fechamento.component.scss']
})
export class FechamentoComponent implements OnInit,OnDestroy {
 
  objAtivos: any[];

  displayedColumnsAtivos = ['id', 'idEntidade','nome', 'limiteXml', 
                            'totalConsumidoXml','vExcedente','vMensal','vTotal',
                            'precoExcedente'];

//   displayedColumnsInativos = ['id', 'nome', 'login', 'action'];
  
  getSubscription: Subscription;
  postSubscription: Subscription;
  delSubscription: Subscription;
  dialogSubscription: Subscription;

  
  periodos: [{mes: number, ano: number}];
  idPeriodo: number = 0;
  fechamento: Fechamento[];


  selectedIndex = 0;
  search: String;
  currentPageAtivos: number;
  lengthAtivos = 0;
  pageSizeAtivos = 25;
  pageSizeOptionsAtivos: number[] = [25, 50, 100];
  pageEvent: PageEvent;

  currentPageInativos: number;
  lengthInativos = 0;
  pageSizeInativos = 25;
  pageSizeOptionsInativos: number[] = [25, 50, 100];

  constructor(private fechamentoService: FechamentoService,
    private _route: ActivatedRoute,
    private _dialogService: DialogService,
    public dialog: MatDialog
    ) { }

  ngOnInit(): void {
    this.currentPageAtivos = 1;
    this.currentPageInativos = 1;
    this.getSubscription = this.fechamentoService.periodo()
     .subscribe((res: any) => {
       this.periodos = res.data;
       console.log(this.periodos);
     });
    //  this.filtrar();
  }

  ngOnDestroy(): void {
    if(this.getSubscription)this.getSubscription.unsubscribe();
    if(this.postSubscription)this.postSubscription.unsubscribe();
    if(this.delSubscription)this.delSubscription.unsubscribe();
    if(this.dialogSubscription)this.dialogSubscription.unsubscribe();

  }


  simular(): void {
    const dialogRef = this.dialog.open(ModalCriarFechamentoComponent, {
      width: '400px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

async filtrar(){
  await this.fechamentoService.read(
     this.currentPageAtivos, this.pageSizeAtivos,
     this.periodos[this.idPeriodo].mes,
     this.periodos[this.idPeriodo].ano
     )
  .then((res: any) => {
        this.fechamento = res.entidade;
        // this.clientesInativos = res.entidade;
        this.lengthAtivos = res.totalPage;
    this.fechamento = res.fechamento;
    console.log(this.fechamento);
  });
};

clickPageAtivos(event: PageEvent) {
  this.currentPageAtivos = event.pageIndex;
  this.pageSizeAtivos = event.pageSize;
  this.filtrar();
  return event;
}

changePeriodo(id:number){
 this.idPeriodo = id;
}

}

export interface PeriodicElement {
    id: number;
    codCliente: number;
    nomeCliente: string;
    qtdXML: number;
    qtdConsumida: number;
    valExc: number;
    valMensal: number;
    valTotal: number;
    valPorExc: number;
    // action: string;
  }

