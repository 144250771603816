<br><br><br>
<mat-card>
    <div class="teste">
        <i class="material-icons">
            work
        </i>
        <span>
            Escritórios Contábeis
        </span>
    </div>
    <br>
<div fxLayout="row">
    <mat-form-field class="search-field" appearance="outline">
        <mat-icon (click)="Search()" matSuffix>search</mat-icon>
        <input matInput placeholder="Pesquise aqui" [(ngModel)]="search" (keyup.enter)="Search()">
    </mat-form-field>
    <button class="botaoPrimario" mat-raised-button (click)="Add()" fxLayoutAlign="end center">
        Novo Escritório
    </button>
</div>


<mat-tab-group
(selectedTabChange)="tabClick($event)"
animationDuration="0ms" [(selectedIndex)]=selectedIndex>
    <mat-tab label="Ativos">
        <div class="mat-elevation-z4">
            <table mat-table [dataSource]="objAtivos">
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef>#ID</th>
                    <td mat-cell *matCellDef="let row">{{row.id}}</td>
                </ng-container>

                <ng-container matColumnDef="nome">
                    <th mat-header-cell *matHeaderCellDef>Nome</th>
                    <td mat-cell *matCellDef="let row">{{row.nome}}</td>
                </ng-container>

                <ng-container matColumnDef="razao">
                    <th mat-header-cell *matHeaderCellDef>Razão Social</th>
                    <td mat-cell *matCellDef="let row">{{row.razao}}</td>
                </ng-container>
                <ng-container matColumnDef="cnpj">
                    <th mat-header-cell *matHeaderCellDef>CNPJ</th>
                    <td mat-cell *matCellDef="let row">{{row.cnpj}}</td>
                </ng-container>
                <ng-container matColumnDef="cpf">
                    <th mat-header-cell *matHeaderCellDef>CPF</th>
                    <td mat-cell *matCellDef="let row">{{row.CPF}}</td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef>Ações</th>
                    <td mat-cell *matCellDef="let row">
                        <a matTooltip="Editar" routerLink="/contabilidade/edit/{{ row.id }}" class="edit">
                            <i class="material-icons">
                                edit
                            </i>
                        </a>
                        <button style="right:0px;" class="delete-btn" (click)="desativarObj(row.id)">
                            <mat-icon matTooltipPosition="right" matTooltipClass="allow-cr" matTooltip="Deletar">
                                delete_outline</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsAtivos"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsAtivos;"></tr>
            </table>
        </div>
        <mat-paginator [length]="lengthAtivos" [pageSize]="pageSizeAtivos" [pageSizeOptions]="pageSizeOptionsAtivos"
            (page)="pageEvent = clickPageAtivos($event)">
        </mat-paginator>

    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <span (click)="getAtivos()">Inativos</span>
        </ng-template>
        <div class="mat-elevation-z4">
            <table mat-table [dataSource]="objInativos">
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef>#ID</th>
                    <td mat-cell *matCellDef="let row">{{row.id}}</td>
                </ng-container>
                <ng-container matColumnDef="nome">
                    <th mat-header-cell *matHeaderCellDef>Nome</th>
                    <td mat-cell *matCellDef="let row">{{row.nome}}</td>
                </ng-container>
                <ng-container matColumnDef="razao">
                    <th mat-header-cell *matHeaderCellDef>Razão Social</th>
                    <td mat-cell *matCellDef="let row">{{row.razao}}</td>
                </ng-container>
                <ng-container matColumnDef="cnpj">
                    <th mat-header-cell *matHeaderCellDef>CNPJ</th>
                    <td mat-cell *matCellDef="let row">{{row.cnpj}}</td>
                </ng-container>
                <ng-container matColumnDef="cpf">
                    <th mat-header-cell *matHeaderCellDef>CPF</th>
                    <td mat-cell *matCellDef="let row">{{row.CPF}}</td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef>Ações</th>
                    <td mat-cell *matCellDef="let row">
                        <a routerLink="/contabilidade/edit/{{ row.id }}" class="edit">
                            <i class="material-icons">
                                edit
                            </i>
                        </a>
                        <button style="right:0px;" class="delete-btn" (click)="ativarObj(row.id)">
                            <mat-icon matTooltipPosition="right" matTooltipClass="allow-cr" matTooltip="Ativar">restore
                            </mat-icon>
                        </button>
                    </td>

                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsInativos"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsInativos;"></tr>
            </table>
        </div>
        <mat-paginator [length]="lengthInativos" [pageSize]="pageSizeInativos"
            [pageSizeOptions]="pageSizeOptionsInativos" (page)="pageEvent = clickPageInativos($event)">
        </mat-paginator>
    </mat-tab>
</mat-tab-group>
</mat-card>