import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ClienteContabil } from 'src/app/models/contabilidade.model';
import { DialogService } from 'src/app/services/dialog.service';
import { GrupoEmpresaService } from 'src/app/services/grupoempresa.service';

@Component({
  selector: 'app-list-contabil-clientes',
  templateUrl: './list-contabil-clientes.component.html',
  styleUrls: ['./list-contabil-clientes.component.scss']
})
export class ListContabilClientesComponent implements OnInit, OnDestroy {

  idPri: number;
  objAtivos: ClienteContabil[];
  idGrupo:number[];

  displayedColumnsAtivos = ['id', 'nome', 'razao', 'action'];
  displayedColumnsInativos = ['id', 'nome', 'razao', 'action'];

  constructor(
    private _grupoEmpresaService: GrupoEmpresaService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _dialogService: DialogService,
    public snackBar: MatSnackBar) { }

  getSubscription: Subscription;
  postSubscription: Subscription;
  delSubscription: Subscription;
  dialogSubscription: Subscription;
  selectedIndex = 0;
  search: String;
  currentPageAtivos: number;
  lengthAtivos = 0;
  pageSizeAtivos = 25;
  pageSizeOptionsAtivos: number[] = [25, 50, 100];
  pageEvent: PageEvent;
  objeto: any;
  ngOnInit(): void {
    this.idPri = +this._route.snapshot.paramMap.get("id");
    this.search = '';
    this.currentPageAtivos = 1;
    this.getAtivos();
  }

  ngOnDestroy(): void {
    if(this.getSubscription){
      this.getSubscription.unsubscribe();
    }
    if(this.postSubscription){
      this.postSubscription.unsubscribe();
    }
    if(this.delSubscription){
      this.delSubscription.unsubscribe();
    }
    if(this.dialogSubscription){
      this.dialogSubscription.unsubscribe();
    }
  }

  clickPageAtivos(event: PageEvent) {
    this.currentPageAtivos = event.pageIndex + 1;
    this.pageSizeAtivos = event.pageSize;
    this.getAtivos(event);
    return event;
  }

  Search() {
      this.getAtivos();
  }
  public getAtivos(event?: PageEvent) {
    this.selectedIndex = 0;
    this.getSubscription = this._grupoEmpresaService.getByClienteContabil(this.idPri,this.search)
      .subscribe((res: any) => {
        this.objeto = res.grupoEmpresa; 
        this.idGrupo = res.grupoEmpresa.map(x => x.id);
        this.lengthAtivos = res.totalActive;
        this.objAtivos = this.objeto.map(x => x.EntidadeSecundario);
      });
    return event;
  }

  editarCliente(id) {
    this._router.navigate(['/clientes/edit/' + id]);
  }

  novoVinculo() {
    this.dialogSubscription = this._dialogService
        .vinculaClienteEscritorio(this.idPri, 'E')
        .afterClosed()
        .subscribe((res:any) => this.getAtivos());
    }

  desvincular(index) {
    let autohide = 3000;
    let msg = "Deseja desvincular cliente?";
    let botao = "desvincular";
    this._dialogService
      .openConfirmDialog(msg, botao)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.getSubscription = this._grupoEmpresaService.deleteClienteContabil(this.idGrupo[index])
          .subscribe((res:any)=>{
            this.snackBar.open(res.message, undefined, {
              duration: autohide,
              panelClass: ['ok-snackbar']
            });
            this.getAtivos();
          });
        }
      });
  }
}
