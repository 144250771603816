import { Component, OnInit } from '@angular/core';
import { yearsPerPage } from '@angular/material/datepicker';
import { MatDialogRef } from '@angular/material/dialog';

interface Mes {
  value: string;
  viewValue: string;
}


@Component({
  selector: 'app-modal-criar-fechamento',
  templateUrl: './modal-criar-fechamento.component.html',
  styleUrls: ['./modal-criar-fechamento.component.scss']
})


export class ModalCriarFechamentoComponent implements OnInit {
  meses: Mes[] = [
    {value: '1', viewValue: 'Janeiro'},
    {value: '2', viewValue: 'Fevereiro'},
    {value: '3', viewValue: 'Março'},
    {value: '4', viewValue: 'Abril'},
    {value: '5', viewValue: 'Maio'},
    {value: '6', viewValue: 'Junho'},
    {value: '7', viewValue: 'Julho'},
    {value: '8', viewValue: 'Agosto'},
    {value: '9', viewValue: 'Setembro'},
    {value: '10', viewValue: 'Outubro'},
    {value: '11', viewValue: 'Novembro'},
    {value: '12', viewValue: 'Dezembro'},
  ];

  currentTime = new Date();
  year = this.currentTime.getFullYear()
  maxYear: number = this.year;
  minYear: number = this.year - 1;

  constructor(
    public dialogRef: MatDialogRef<ModalCriarFechamentoComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  ngOnInit(): void {

    
  }

  save(){

  }
  
  cancel(): void {
    this.dialogRef.close();
  }
}
