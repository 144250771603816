<div style="background-image: url('assets/img/bg.png'); background-size: cover;">
 <!-- no-repeat center bottom fixed; background-size: cover; -->
    <div class="row center" style="height: 100vh;" >
        <form [formGroup]="form" class="col-3 login-form" (ngSubmit)="submit()" style="background-color: rgb(255, 255, 255); max-height: 80px;">
            <img  src="assets/img/Logo.png" alt="logo" class="logo Kiron" style="max-height: 55px;">
            <mat-form-field class="inputMedium" appearance="outline">
                <mat-label>
                    email
                </mat-label>
                <mat-icon matSuffix>person_outline</mat-icon>
                <input matInput maxlength="60" type="text" tabindex="1" formControlName="login">
            </mat-form-field>

            <mat-form-field class="inputMedium" appearance="outline">
                <mat-label>
                    senha
                </mat-label>
                <mat-icon matSuffix>vpn_key</mat-icon>
                <input matInput formControlName="senha" maxlength="120" type="password" minlenght="1" required>
            </mat-form-field>

            <!-- <mat-checkbox checked="true" matInput formControlName="userLoggedForm" >
                <label>Mantenha-me conectado </label>
            </mat-checkbox> -->

            <!-- <span class="spanLink" >
                Esqueceu a senha?
                <a class="link">clique aqui</a>
            </span> -->
        <br>
            
            <button mat-button class="btnLogin" [disabled]="(form.invalid) || (form.pristine)" type="submit">
                <!-- style="background-color: rgb(2, 117, 216); color: rgb(255, 255, 255); -->
                Acessar
            </button>
        </form>
    </div>
</div>
